import routeHash from './routeHash';

export const closeModal = () => routeHash.push({}, true);

export const openModal = (modalName: string, rest?: {[key: string]: any}) => routeHash.push(
  {
    modal: modalName,
    ...(rest || {}),
  },
  true,
);
