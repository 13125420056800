import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    name: _ctx.modalName,
    title: _ctx.$t('Paystree countries list title')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t("paystree countries list")), 1)
    ]),
    _: 1
  }, 8, ["name", "title"]))
}