/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { defineModule } from 'direct-vuex';
import { modActionCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import { basicOfficeFetch, State } from '@/store/storeUtils';
import { INIT, PENDING, FULFILLED } from '@/utils/statuses';

interface WithdrawTokensStoreState {
  state: State;
  data: any;
}

const withdrawTokensStore = defineModule({
  namespaced: true,
  state: {
    state: INIT,
    data: '',
  } as WithdrawTokensStoreState,

  getters: {},
  mutations: {
    SET_STATE(state, { fetchState, data }: { fetchState: State; data?: any }) {
      state.state = fetchState;

      if (fetchState === INIT || fetchState === FULFILLED) {
        state.data = '';
      }
      if (data) {
        state.data = data;
      }
    },
  },
  actions: {
    fetch(
      ctx,
      payload: {
        callback?: any;
        amount: number | string;
        currency?: number | string;
        destination?: string;
        comission?: 'eur' | 'btc';
      },
    ) {
      const { commit, state, rootDispatch } = actionCtx(ctx);

      if (state.state === PENDING) {
        return;
      }

      rootDispatch.twoFaStore.with2FAData((two) => {
        basicOfficeFetch({
          method: api.office.sendPost,
          props: {
            url: '/transaction/withdraw',
            data: {
              amount: payload.amount,
              currency: payload.currency,
              destination: payload.destination,
              comission: payload.comission,
              two,
            },
          },
          setState: commit.SET_STATE,
          callback: (fetchState) => {
            if (fetchState === FULFILLED) {
              rootDispatch.userStore.fetch();
              rootDispatch.userFrozenOtonStore.fetch();
              commit.SET_STATE({ fetchState: INIT });
            }
            if (typeof payload.callback === 'function') {
              payload.callback(fetchState);
            }
          },
        });
      });
    },
  },
});

export default withdrawTokensStore;
// const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, withdrawTokensStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, withdrawTokensStore);
