/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { defineModule } from 'direct-vuex';
import { RouteLocationNormalized } from 'vue-router';
import { modGetterCtx } from '@/store/index';
import router from '@/router';
import qs, { ParsedQs } from 'qs';

export interface RouteState extends Omit<RouteLocationNormalized, 'matched' | 'redirectedFrom'> {
  from?: Omit<RouteState, 'from'>;
}

function cloneRoute(to: RouteLocationNormalized, from?: RouteLocationNormalized): RouteState {
  const clone: RouteState = {
    ...to,
    name: to.name,
    path: to.path,
    hash: to.hash,
    query: to.query,
    params: to.params,
    fullPath: to.fullPath,
    meta: to.meta,
  };

  if (from) {
    clone.from = cloneRoute(from);
  }

  return Object.freeze(clone);
}

const routeStore = defineModule({
  namespaced: true,
  state: {
    route: cloneRoute(router.currentRoute.value),
  } as {
    route: RouteState;
  },

  getters: {
    hash(...args): ParsedQs {
      const { state } = getterCtx(args);

      const query = qs.parse(state.route.hash.replace(/^#/, ''));

      return query || {};
    },

    currentModalOpen(...args): string {
      const { getters } = getterCtx(args);
      const currentModal = getters.hash.modal;
      if (typeof currentModal === 'string') {
        return currentModal;
      }
      return '';
    },

    currentModalData(...args): { [key: string]: any } | any[] {
      const { getters } = getterCtx(args);
      const { data } = getters.hash;

      if (typeof data === 'object') {
        return data;
      }

      return {};
    },
  },

  mutations: {
    ROUTE_CHANGED(
      state,
      transition: { to: RouteLocationNormalized; from: RouteLocationNormalized },
    ): void {
      state.route = cloneRoute(transition.to, transition.from);
    },
  },
});

export default routeStore;
const getterCtx = (args: [{ route: RouteState }, any, any, any]) => modGetterCtx(args, routeStore);
// const actionCtx = (ctx: any) => localActionContext(ctx, routeStore);
