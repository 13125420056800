import { CodesInterface } from './apiTypes';

export default {
  0: 'success',
  1: 'invalid data',
  2: 'access denied',
  3: 'Invalid 2fa code or password',
  4: 'pin not found',
  5: 'invalid token',
  401: 'authentication is required',
} as CodesInterface;
