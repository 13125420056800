import router from '@/router';
import qs from 'qs';
import { keys } from 'rambda';

export const hashToString = (data: any) => {
  if (typeof data !== 'object') {
    return '#';
  }

  const newData: { [key: string]: any } = {};

  keys(data).forEach((key) => {
    const value = data[key];
    if (value) {
      newData[key as string] = value;
    }
  });

  return `#${qs.stringify(newData, { encode: false })}`;
};

const setNewHash = (routeMethod: 'replace' | 'push') => (
  rest: { [key: string]: any },
  isNewState?: boolean,
) => router[routeMethod]({
  hash: hashToString({
    ...(isNewState ? {} : qs.parse(router.currentRoute.value.hash.replace(/^#/, '')) || {}),
    ...(rest || {}),
  }),
});

export default {
  replace: setNewHash('replace'),
  push: setNewHash('push'),
};
