
import store from '@/store';
import {
  computed, defineComponent, ref,
} from 'vue';
import '@/assets/icons/close-icon.svg?sprite';

import { pathOr } from 'rambda';
import { useI18n } from 'vue-i18n';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonButtonSecondary from './CommonButtonSecondary.vue';
import CommonInput from './CommonInput.vue';
import CommonModal from './CommonModal.vue';

export default defineComponent({
  name: 'ModalTwoAuth',
  components: {
    CommonButtonSecondary, CommonButtonPrimary, CommonInput, CommonModal,
  },
  setup() {
    const inputRef: any = ref(null);
    const { t } = useI18n({ useScope: 'global' });
    const userMail = computed(() => pathOr('', 'email', store.getters.userStore.data));
    const isOpen = computed(() => !!store.getters.twoFaStore.isOpen);

    const isTwoAuth = computed(() => store.getters.userStore.userWith2Fa);
    const value = computed(() => store.state.twoFaStore.twoFa);

    const dynamicInputProps = computed(() => (
      {
        label: (isTwoAuth.value ? t('2FA code') : t('Your password')),
        type: isTwoAuth.value ? 'text' : 'password',
        autocomplete: !isTwoAuth.value ? 'current-password' : '',
      }
    ));

    const setInputRef = (el: any) => { inputRef.value = el; };
    const closeHandler = store.dispatch.twoFaStore.close;
    const onSubmit = store.dispatch.twoFaStore.send;
    const onChange = store.commit.twoFaStore.CHANGE;

    const opened = () => {
      inputRef.value?.focus?.();
    };

    return {
      inputRef,
      setInputRef,
      opened,
      dynamicInputProps,
      userMail,
      isOpen,
      isTwoAuth,
      value,
      closeHandler,
      onSubmit,
      onChange,
    };
  },
});
