/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { AxiosResponse } from 'axios';

import { pathOr, clone } from 'rambda';

import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import { basicOfficeFetch, State } from '@/store/storeUtils';
import {
  INIT, PENDING, FULFILLED,
} from '@/utils/statuses';
import { LinearTreeUser } from '@/interfaces/LinearTypes';

export type LinearResponse = AxiosResponse<{ data: LinearTreeUser }>;
export type LinearDataType = {[key: number]: LinearTreeUser[]};

export interface LinearStateInterface {
  data: LinearDataType;
  fetchState: State;

  filters: {
    search: ''
  },
}

const initialState: LinearStateInterface = {
  data: {},
  fetchState: INIT,

  filters: {
    search: '',
  },
};

const transactionsStore = defineModule({
  namespaced: true,
  state: clone(initialState),

  getters: {
    fetchState(...args): State {
      const { state } = getterCtx(args);

      if (Object.keys(state.data).length <= 0) {
        return state.fetchState;
      }
      return FULFILLED;
    },
    users(...args): LinearDataType {
      const { state } = getterCtx(args);

      return state.data;
    },
  },
  mutations: {
    CLEAR_STORE(state) {
      Object.assign(state, clone(initialState));
    },
    SET_STATE(state, { fetchState, data, userId }: { fetchState: State; data?: LinearResponse, userId?: number }) {
      state.fetchState = fetchState;

      if (data && userId) {
        const list = pathOr([], 'data.data', data);

        state.data[userId] = list;
      }
    },
    SET_INIT(state) {
      state.fetchState = INIT;
    },
  },
  actions: {
    async fetch(ctx, props: { fromZero?: boolean, userId: number }) {
      const { commit, state } = actionCtx(ctx);

      if (state.data[props.userId]) {
        return;
      }

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/linear/data',
          data: {
            user_id: props.userId,

          },
        },
        setState: ({ fetchState }) => {
          if (fetchState !== FULFILLED) {
            commit.SET_STATE({ fetchState });
          }
        },
        callback: (fetchState, response) => {
          if (fetchState === FULFILLED) {
            commit.SET_STATE({ fetchState, data: response, userId: props.userId });
          }
        },
      });
    },
  },
});

export default transactionsStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, transactionsStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, transactionsStore);
