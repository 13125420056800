import 'vue-toastification/dist/index.css';
import '@vueform/multiselect/themes/default.css';
import 'v-calendar/dist/style.css';

import VueFinalModal from 'vue-final-modal';
import Toast from 'vue-toastification';
import Maska from 'maska';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { createApp } from 'vue';
import {
  Chart,
  LineController,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarController,
  BarElement,
} from 'chart.js';
import {
  INIT, PENDING, FULFILLED, REJECTED,
} from './utils/statuses';
import App from './App.vue';
import i18n from './langs/i18n';
import router from './router';
import store from './store';
import getDoc, { docsInfo } from './utils/getDoc';

dayjs.extend(duration);
dayjs.extend(customParseFormat);

Chart.register(
  LineController,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarController,
  BarElement,
);

export const vueApp = createApp(App)
  .use(i18n)
  .use(Toast)
  .use(store.original)
  .use(router)
  .use(Maska)
  .use(VueFinalModal());

vueApp.config.globalProperties = {
  ...vueApp.config.globalProperties,
  $d: function $date(date: number | string, type: any) {
    const $d = i18n.global.d;
    if (typeof date === 'number') {
      return $d(date, type);
    }

    if (typeof date === 'string') {
      return $d(new Date(date), type);
    }

    console.error('Invalid Date');
    return 'Invalid Date';
  },
  $t: function $t(a: any, b: any, c: any) {
    return i18n.global.t(a, b, c);
  },

  $getStaticDocument: function $getStaticDocument(docName: keyof typeof docsInfo, locale: string) {
    return getDoc(docName, locale);
  },
  $status: {
    INIT,
    PENDING,
    FULFILLED,
    REJECTED,
  },
};

vueApp.mount('#app');
