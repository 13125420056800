

import { defineComponent, reactive } from 'vue';
import { modalsNames } from '@/utils/modals';
import { api } from '@/utils/apiInstance';
import { basicOfficeFetch } from '@/store/storeUtils';
import CommonModal from './CommonModal.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonLoader from './CommonLoader.vue';

export default defineComponent({
  name: 'ModalOpenSupport',
  components: { CommonModal, CommonButtonPrimary, CommonLoader },

  setup() {
    const modalName = modalsNames.modalOpenSupport;

    const formData = reactive({
      state: 'INIT',
      data: '',
    });

    const fetchSupportLink = () => {
      basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/index/omnidesk',
        },
        setState: ({ fetchState }) => {
          formData.state = fetchState;
        },
        callback: (fetchState, data) => {
          if (fetchState === 'FULFILLED') {
            if (typeof data.data.data[0] === 'string' && data.data.data[0].includes('https://')) {
              [formData.data] = data.data.data;
            }
          }
        },
      });
    };

    return {
      fetchSupportLink,
      modalName,
      formData,
    };
  },
});
