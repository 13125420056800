
import {
  defineComponent, onBeforeMount,
} from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import Cookies from 'js-cookie';
import store from './store';

import ModalTwoAuth from './components/ModalTwoAuth.vue';
import ModalDefrostQualify from './components/ModalDefrostQualify.vue';
import TechWorksPlaceholder from './views/TechWorksPlaceholder.vue';
import ModalInfo from './components/ModalInfo.vue';
import ModalConfirm from './components/ModalConfirm.vue';
import ModalListOfCountries from './components/ModalListOfCountries.vue';
import ModalPaymentSuccess from './components/ModalPaymentSuccess.vue';
import ModalPaymentFail from './components/ModalPaymentFail.vue';
import ModalWithdrawFlc from './components/ModalWithdrawFlc.vue';
import ModalWithdrawFlcAccept from './components/ModalWithdrawFlcAccept.vue';
import ModalNewContract from './components/ModalNewContract.vue';
import ModalTopUp from './components/ModalTopUp.vue';
import ModalCreateServer from './components/ModalCreateServer.vue';
import ModalOpenSupport from './components/ModalOpenSupport.vue';
import ModalNewEmail from './components/ModalNewEmail.vue';
import ModalConfirmationEmail from './components/ModalConfirmationEmail.vue';
import ModalProductDetails from './components/ModalProductDetails.vue';

export default defineComponent({
  components: {
    ModalTwoAuth,
    ModalDefrostQualify,
    TechWorksPlaceholder,
    ModalInfo,
    ModalConfirm,
    ModalListOfCountries,
    ModalPaymentSuccess,
    ModalPaymentFail,
    ModalWithdrawFlc,
    ModalWithdrawFlcAccept,
    ModalNewContract,
    ModalTopUp,
    ModalCreateServer,
    ModalOpenSupport,
    ModalNewEmail,
    ModalConfirmationEmail,
    ModalProductDetails,
  },
  name: 'App',

  setup() {
    const { replace } = useRouter();
    const { locale } = useI18n();
    const hasTestCookie = Cookies.get('tester') === '527';
    let showTechWorks = false;

    if (hasTestCookie) {
      showTechWorks = false;
    }

    if (showTechWorks) {
      return { showTechWorks, locale };
    }

    onBeforeMount(() => {
      const { pathname } = window.location;

      const { isAuth } = store.state.userStore;

      const reLogin = /^((\/login\/?)|(\/ref\/))/;

      if (isAuth) {
        store.dispatch.userStore.fetch();
        if (reLogin.test(pathname)) {
          replace({ path: '/' });
        }
      } else if (!reLogin.test(pathname)) {
        const queryParamsString = window.location.search;
        const queryParams = new URLSearchParams(queryParamsString);
        const token = queryParams.get('token');
        const confirmToken = queryParams.get('confirmation-token');
        if (pathname === '/profile') {
          if (token) {
            Cookies.set('changeEmailToken', String(token));
          } else if (confirmToken) {
            Cookies.set('confirmationToken', String(confirmToken));
          }
        }
        replace({ path: '/login' });
      }
    });

    return {
      locale,
      showTechWorks,
    };
  },
});
