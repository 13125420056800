

import { defineComponent } from 'vue';
import modals, { modalsNames } from '@/utils/modals';
import CommonModal from './CommonModal.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';

export default defineComponent({
  name: 'ModalNewEmail',
  components: { CommonModal, CommonButtonPrimary },

  setup() {
    const modalName = modalsNames.modalNewEmail;

    const onClose = modals.modalNewEmail.closeModal;

    return {
      modalName,
      onClose,
    };
  },
});
