import { round10 } from './rounding';

export const getPercentTwoDiff = (one: number, two: number) => {
  const full = one;
  const onePercent = full / 100;
  const currentPercents = two / onePercent;
  const diff = currentPercents - 100;

  return round10(diff, -2);
};
