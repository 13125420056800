
import { defineComponent } from 'vue';
import '@/assets/icons/no-data-icon.svg';

export default defineComponent({
  name: 'NoData',

  components: {},

  props: {
    noDataText: String,
    datatError: Boolean,
  },
});
