/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { AxiosResponse } from 'axios';
import { path } from 'rambda';
import { ChartPriceItemInterface, MappedChartItemInterface } from '@/interfaces/OtonChartTypes';
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import {
  FetchStateInterface,
  getFetchState,
  getFetchMutations,
  basicAdapterFetch,
} from '@/store/storeUtils';
import { INIT, PENDING } from '@/utils/statuses';

import { getPercentTwoDiff } from '@/utils/getPercentTwoDiff';
import { mapPriceChartItems } from '@/utils/api/mapChartItems';

export type OtonVolumeResponse = AxiosResponse<{ data: ChartPriceItemInterface }>;

const otonRateStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<OtonVolumeResponse>(),
  } as FetchStateInterface<OtonVolumeResponse>,

  getters: {
    response(...args): ChartPriceItemInterface[] {
      const { state } = getterCtx(args);

      const result = path('data.data', state.response) as ChartPriceItemInterface[];

      return result || [];
    },
    data(...args): MappedChartItemInterface[] {
      const { getters } = getterCtx(args);

      return mapPriceChartItems([...getters.response]);
    },
    current(...args): number {
      const { getters } = getterCtx(args);

      const lastItem = getters.data.slice(-1)[0];

      if (!lastItem) {
        return 0;
      }

      return lastItem.y;
    },
    oneDayDiff(...args): number {
      const { getters } = getterCtx(args);

      const items = getters.response.slice(-2);

      if (!items || items.length === 0) {
        return 0;
      }

      let first = 0;
      let second = 0;

      if (items.length === 2) {
        first = +items[0].rate;
        second = +items[1].rate;
      } else if (items.length === 1) {
        first = 0;
        second = +items[0].rate;
      }

      return getPercentTwoDiff(first, second);
    },
  },
  mutations: {
    ...getFetchMutations(),
    SET_INIT(state) {
      state.fetchState = INIT;
    },
  },
  actions: {
    async fetch(ctx) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicAdapterFetch({
        method: () => api.adapter.sendByGet(
          '/lp/oton-usdt/statistics/rate',
        ),
        setState: commit.SET_STATE,
      });
    },
  },
});

export default otonRateStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, otonRateStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, otonRateStore);
