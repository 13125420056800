
import { defineComponent } from 'vue';
import '@/assets/icons/simple-oton-logo.svg?sprite';
import CommonLoader from './CommonLoader.vue';
import NoData from './NoData.vue';

export default defineComponent({
  name: 'WithLoader',
  components: { CommonLoader, NoData },
  props: {
    state: String,
    isPlaceholder: Boolean,
    active: Boolean,
    top: Boolean,
    transitionName: String,
  },
});
