
import store from '@/store';
import {
  computed, defineComponent,
} from 'vue';
import '@/assets/icons/close-icon.svg?sprite';

import modals, { modalsNames } from '@/utils/modals';
import routeHash from '@/utils/routeHash';
import { FULFILLED, INIT } from '@/utils/statuses';
import { State } from '@/store/storeUtils';
import CommonModal from './CommonModal.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonButtonSecondary from './CommonButtonSecondary.vue';
import CommonLoader from './CommonLoader.vue';

export default defineComponent({
  name: 'ModalWithdrawFlcAccept',
  components: {
    CommonButtonSecondary, CommonButtonPrimary, CommonModal, CommonLoader,
  },
  setup() {
    const withdrawState = computed(() => {
      const { state } = store.state.withdrawFlcStore;
      if (state === INIT) {
        return FULFILLED;
      }
      return state;
    });
    const modalName = modalsNames.modalWithdrawFlcAccept;

    const hash = computed(() => store.getters.routeStore.hash);
    const hideContent = computed(() => !hash.value.modal);
    const isDone = computed(() => !!hash.value.isDone || !hash.value.modal);
    const isTwoAuth = computed(() => store.getters.userStore.userWith2Fa);

    const isError = computed(() => store.state.withdrawFlcStore.isError);

    const data = computed(() => ({
      bep20: (hash.value.bep20 || '') as string,

      sum: hash.value.sum as string || '',
    }));

    const closeHandler = modals.modalWithdrawFlcAccept.closeModal;
    const onBack = () => modals.modalWithdrawFlc.openModal(data.value);
    const onSend = () => {
      const {
        sum, bep20,
      } = data.value;
      const destination = String(bep20);
      store.dispatch.withdrawFlcStore.fetch({
        callback(state: State) {
          if (state === FULFILLED && isTwoAuth.value && !isError.value) {
            routeHash.replace({ ...data.value, isDone: '1' });
          }
        },
        amount: sum,
        destination,
      });
    };

    return {
      withdrawState,
      modalName,
      isDone,
      hideContent,
      data,
      closeHandler,
      onBack,
      onSend,
    };
  },
});
