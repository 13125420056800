/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { path } from 'rambda';
import getAuth from '@/utils/cookies/getAuth';
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';
import Cookies from 'js-cookie';

import {
  State,
  FetchOfficeState,
  getFetchState,
  getFetchMutations,
  basicOfficeFetch,
} from '@/store/storeUtils';

import {
  FULFILLED, INIT, PENDING, REJECTED,
} from '@/utils/statuses';
import getLang from '@/utils/cookies/getLang';
import router from '@/router';
import qs from 'qs';

import authCookies from '@/utils/cookies/authCookies';

export interface UserResponseInterface {
  id: number;
  email: string,
  name: string,
  phone: number | string,
  blockchainAddress: string,
  country: string,
  ref: number,
  auth: string;
  nodes: [];
  packet: { id: 1, title: string };
  rank: number;
  buy_info: number | boolean;
  '2FA': boolean;
}

export interface UserStoreInterface extends FetchOfficeState<UserResponseInterface> {
  isAuth: boolean;
  isForgot: boolean;
  errorMessage: string;
  with2FaAuth: boolean;
  updatedEmail: string;
}

const userStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<UserResponseInterface>(),
    isAuth: !!getAuth(),
    with2FaAuth: false,
    updatedEmail: '',
  } as UserStoreInterface,

  getters: {
    data(...args): UserResponseInterface | undefined {
      const { state } = getterCtx(args);

      const result = path(['data', 'data'], state.response) as UserResponseInterface;

      return result;
    },
    userWith2Fa(...args): boolean {
      const { state, getters } = getterCtx(args);

      const result = state.with2FaAuth || getters?.data?.['2FA'] || false;

      return result;
    },

  },
  mutations: {
    ...getFetchMutations(),
    SET_AUTH(state, isAuth: boolean) {
      state.isAuth = isAuth;
    },
    SET_INIT(state) {
      state.fetchState = INIT;
    },
    UPDATE_DATA(state, payload: any) {
      if (!state.response) {
        return;
      }

      const newResponse = {
        ...state.response,
        data: {
          ...state.response.data,
          data: {
            ...state.response.data.data,
            ...payload,
          },
        },
      };

      state.response = newResponse;
    },
    UPDATE_FORGOT(state, payload: boolean) {
      state.isForgot = payload;
    },
    SET_TWO_AUTH(state, two: boolean) {
      state.with2FaAuth = two;
    },
    SET_ERROR_MESSAGE(state, message) {
      state.errorMessage = message;
    },
    SET_UPDATED_EMAIL(state, value) {
      state.updatedEmail = value;
    },
  },
  actions: {
    async fetch(ctx) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/user/info',
        },
        setState: commit.SET_STATE,
        // update state
        callback: (fetchState) => {
          if (fetchState === REJECTED) {
            dispatch.dropAuth();
          }
        },
      });
    },

    async register(ctx, payload: {
      email: string;
      password: string;
      password1: string;
      ref: string | number | undefined;
      place: 'left' | 'right' | 'default' | undefined;
      name: string;
      phone: string | number;
      country: string;
    }) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/user/register',
          data: {
            ...payload,
          },
        },
        setState: commit.SET_STATE,
        callback: async (fetchState) => {
          if (fetchState === REJECTED) {
            dispatch.dropAuth({ withoutRedirect: true });
          }

          if (fetchState === FULFILLED) {
            commit.SET_INIT();
            const auth = state.response?.data.data.auth;

            if (!auth) {
              if (!!state.response?.data?.data === true) {
                router.replace('/login/confirm-email');
              } else {
                dispatch.dropAuth();
              }
              return;
            }

            Cookies.set('auth', auth, { expires: 30 });

            await dispatch.fetch();

            commit.SET_AUTH(!!auth);

            router.replace('/');
          }
        },
      });
    },
    async setAuth(ctx, { auth }: { auth?: string; }) {
      const { commit, dispatch } = actionCtx(ctx);
      commit.SET_INIT();

      if (!auth) {
        dispatch.dropAuth();
        return;
      }

      authCookies.set(auth);

      await dispatch.fetch();

      commit.SET_AUTH(!!auth);

      router.replace('/');
    },

    async loginWithKey(ctx, { key, code }: {key: string; code: 4 | 2}) {
      const {
        commit, dispatch, rootDispatch,
      } = actionCtx(ctx);

      // TODO: work with 5000 code with mean force KYC
      const authTypes = {
        2: {
          type: 'two',
          url: '/user/auth2fa',
        },
        4: {
          type: 'pin',
          url: '/user/authEmail',
        },
      };

      const authType = authTypes[code];

      commit.SET_STATE({ fetchState: INIT });

      if (authType.type === 'two') {
        commit.SET_TWO_AUTH(true);
      }

      const authorize = (two: string) => {
        basicOfficeFetch({
          method: api.office.sendPost,
          props: {
            url: authType.url,
            data: {
              key, two,
            },
          },
          setState: ({ fetchState }) => commit.SET_STATE({ fetchState }),
          callback: (fetchState, resp) => {
            if (fetchState === FULFILLED) {
              const auth = resp?.data?.data?.auth;

              if (!auth) {
                dispatch.dropAuth();
                return;
              }

              commit.SET_TWO_AUTH(false);

              dispatch.setAuth({ auth });
            }
          },
        });
      };

      rootDispatch.twoFaStore.with2FAData(authorize);
    },
    async login(ctx, { email, password }: {email: string; password: string}) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      const callback = (fetchState: State, response?: any) => {
        if (fetchState === FULFILLED) {
          const auth = response?.data?.data?.auth;

          if (!auth) {
            dispatch.dropAuth();
            return;
          }

          dispatch.setAuth({ auth });
        }

        if (fetchState === REJECTED && response) {
          if (response?.data?.message && response?.data?.message[0]?.text) {
            commit.SET_ERROR_MESSAGE(response?.data?.message[0]?.text);
          }
          const { code, data } = response.data;
          const key = data?.key;

          if (!key) {
            return;
          }
          dispatch.loginWithKey({ code, key });
        }
      };

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/user/auth',
          data: {
            email, password,
          },
        },
        setState: ({ fetchState }) => commit.SET_STATE({ fetchState }),
        callback,
      });
    },

    async setProfile(ctx, { data, onSuccess }: {data: {[key:string]: any}, onSuccess?(): void }) {
      const {
        commit, state, dispatch, rootDispatch,
      } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      rootDispatch.twoFaStore.with2FAData((two) => {
        basicOfficeFetch({
          method: api.office.sendPost,
          props: {
            url: '/user/setProfile',
            data: {
              two,
              data: JSON.stringify(data),
            },
          },
          setState: ({ fetchState }) => commit.SET_STATE({ fetchState: fetchState === REJECTED ? FULFILLED : fetchState }), // superHach better will be create another one SET_STATE for set profile
          callback: (fetchState) => {
            if (fetchState === FULFILLED) {
              dispatch.fetch();
              onSuccess?.();
            }
          },
        });
      });
    },

    async easyAuth(ctx, token: string) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      const app = process.env.VUE_APP_SSO_TOKEN;
      const ssoUrl = process.env.VUE_APP_SSO_URL;

      await basicOfficeFetch({
        method: () => api.office.clearPost({
          baseURL: ssoUrl,
          url: '',
          data: qs.stringify({ token, app, lang: getLang() }),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }),

        setState: commit.SET_STATE,
        // update state
        callback: async (fetchState) => {
          if (fetchState === REJECTED) {
            dispatch.dropAuth();
          }

          if (fetchState === FULFILLED) {
            commit.SET_INIT();
            const auth = state.response?.data.data.auth;

            if (!auth) {
              dispatch.dropAuth();
              return;
            }

            authCookies.set(auth);

            await dispatch.fetch();

            commit.SET_AUTH(!!auth);

            router.replace('/');
          }
        },
      });
    },

    forgotPassword(ctx, email: string) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/user/forgot',
          data: {
            email,
          },
        },
        setState: commit.SET_STATE,
        callback: (fetchState) => {
          if (fetchState === REJECTED) {
            dispatch.dropAuth({ withoutRedirect: true });
            commit.UPDATE_FORGOT(false);
          }
          if (fetchState === FULFILLED) {
            commit.UPDATE_FORGOT(true);
          }
        },
      });
    },

    saveNewPassword(ctx, {
      code,
      password,
      password1,
    }: {
      code: string,
      password: string;
      password1: string;
    }) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/user/forgotSave',
          data: {
            code,
            password,
            password1,
          },
        },
        setState: commit.SET_STATE,
        callback: (fetchState) => {
          if (fetchState === FULFILLED) {
            router.push('/login');
          }
        },
      });
    },

    dropAuth(ctx, payload?: {withoutRedirect?: boolean}) {
      const { commit } = actionCtx(ctx);

      authCookies.remove();
      commit.SET_AUTH(false);
      if (!(payload || {}).withoutRedirect) {
        router.push('/login');
      }
    },
  },
});

export default userStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, userStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, userStore);
