import {
  basicOfficeFetch,
  FetchStateInterface,
  getFetchState,
  getFetchMutations,
} from '@/store/storeUtils';
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { AxiosResponse } from 'axios';
import { path } from 'rambda';
import { BinaryTreeInterface, MappedBinaryItemInterface, MappedBinaryTreeInterface } from '@/interfaces/BinaryTypes';

import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import { INIT, PENDING } from '@/utils/statuses';

export type BinaryResponse = AxiosResponse<{ data: BinaryTreeInterface }>;

const binaryStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<BinaryResponse>(),
  } as FetchStateInterface<BinaryResponse>,

  getters: {
    data(...args): BinaryTreeInterface | undefined {
      const { state } = getterCtx(args);

      const result = path('data.data', state.response) as BinaryTreeInterface;

      if (Array.isArray(result)) {
        return undefined;
      }

      return result;
    },

    mappedData(...args): MappedBinaryTreeInterface | undefined {
      const { getters: { data } } = getterCtx(args);

      if (!data) {
        return undefined;
      }

      const result: MappedBinaryTreeInterface = {
        0: [undefined],
        1: [undefined, undefined],
        2: [undefined, undefined, undefined, undefined],
      };

      const getItem = (item: BinaryTreeInterface, type: 'Sprout' | 'Seed', isLast?: boolean): MappedBinaryItemInterface => {
        const newItem: MappedBinaryItemInterface = {
          id: item.id,
          account_type: item.account_type,
          name: item?.profile?.name,
          type,
          rank: item?.profile?.rank?.title,
          active: item?.profile?.active,
          children: [],
          ref: item.ref,
          leftBonus: item.binary?.total_left,
          rightBonus: item.binary?.total_right,
        };

        if (item.left) {
          if (!newItem.children) {
            newItem.children = [];
          }
          newItem.children[0] = isLast ? item.left as any : {
            id: item.left.id,
            name: item.left?.profile?.name,
            type: 'Seed',
          };
        }

        if (item.right) {
          if (!newItem.children) {
            newItem.children = [];
          }
          newItem.children[1] = isLast ? item.right as any : {
            id: item.right.id,
            name: item.right?.profile?.name,
            type: 'Sprout',
          };
        }

        return newItem;
      };

      result[0] = [getItem(data, 'Sprout')];

      if (data.left) {
        result[1][0] = getItem(data.left, 'Seed');

        if (data.left.left) {
          result[2][0] = getItem(data.left.left, 'Seed', true);
        }
        if (data.left.right) {
          result[2][1] = getItem(data.left.right, 'Sprout', true);
        }
      }
      if (data.right) {
        result[1][1] = getItem(data.right, 'Sprout');

        if (data.right.left) {
          result[2][2] = getItem(data.right.left, 'Seed', true);
        }
        if (data.right.right) {
          result[2][3] = getItem(data.right.right, 'Sprout', true);
        }
      }

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),
    SET_INIT(state) {
      state.fetchState = INIT;
    },
  },
  actions: {
    async fetch(ctx, { userId }: { userId: string | number }) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/binary/data',
          data: {
            user_id: userId,
          },
        },
        setState: commit.SET_STATE,
        // update state
        callback: () => {
          // console.info({ fetchState });
        },
      });
    },
  },
});

export default binaryStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, binaryStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, binaryStore);
