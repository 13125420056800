import { useI18n } from 'vue-i18n';
import devLog from './devLog';

export interface DocInterface {
  langs: string[];
  type: 'file' | 'link';
  mask?: string;
  links?: { [key: string]: string };
}

export const docsInfo: {
  'terms-of-use': DocInterface;
  'terms-and-conditions': DocInterface;
  'cookies-policy': DocInterface;
  'privacy-notice': DocInterface;
  'marketing-plan': DocInterface;
  'link-example': DocInterface;
  'link-on-table': DocInterface;
  'paystree-card-desc': DocInterface;
  'paystree-cond': DocInterface;
  'paystree-plans': DocInterface;
  'apk': DocInterface;
} = {
  'terms-of-use': {
    type: 'file',
    langs: ['en'],
    mask: 'Terms_of_use.pdf',
  },
  'terms-and-conditions': {
    type: 'file',
    langs: ['en'],
    mask: 'Terms and conditions.pdf',
  },
  'cookies-policy': {
    type: 'file',
    langs: ['en'],
    mask: 'Cookies policy.pdf',
  },
  'privacy-notice': {
    type: 'file',
    langs: ['en'],
    mask: 'PRIVACY_NOTICE.pdf',
  },
  'marketing-plan': {
    type: 'file',
    langs: ['en', 'ru', 'de', 'tr'],
    mask: '3. Marketingplan_oton_{lang}.pdf',
  },
  'link-example': {
    langs: ['ru', 'de', 'tr'],
    type: 'link',
    links: {
      ru: 'https://youtube.com/playlist?list=PLiqpMoO5dyNBiyst0nXw2pNPQHgdOb0I1',
      de: 'https://youtube.com/playlist?list=PLiqpMoO5dyNCHDQx-EZCAeOQYixznG1KQ',
      tr: 'https://youtube.com/playlist?list=PLiqpMoO5dyND36prcKtY57PQ0C8dz1fQG',
    },
  },
  'link-on-table': {
    langs: ['ru', 'en', 'de', 'tr'],
    type: 'link',
    links: {
      ru: 'https://www.dropbox.com/sh/8gl6xp4h490el0j/AAAahBgs4F2XpA7Zlqesj03Xa?dl=0&preview=%D0%A0%D0%B0%D1%81%D1%87%D1%91%D1%82+%D0%BF%D0%BE+%D0%BD%D0%BE%D0%B4%D0%B0%D0%BC+v_2-13.xlsx',
      en: 'https://www.dropbox.com/sh/8gl6xp4h490el0j/AAAahBgs4F2XpA7Zlqesj03Xa?dl=0&preview=Calculation+by+nodes+EN.xlsx',
      de: 'https://www.dropbox.com/sh/8gl6xp4h490el0j/AAAahBgs4F2XpA7Zlqesj03Xa?dl=0&preview=Berechnung+durch+Knoten+DE.xlsx',
      tr: 'https://www.dropbox.com/sh/8gl6xp4h490el0j/AAAahBgs4F2XpA7Zlqesj03Xa?dl=0&preview=D%C3%BC%C4%9F%C3%BCmlere+g%C3%B6re+hesaplama+TR.xlsx',
    },
  },
  'paystree-card-desc': {
    type: 'file',
    langs: ['en', 'ru', 'de'],
    mask: 'paystree_{lang}.pdf?1',
  },
  'paystree-cond': {
    type: 'file',
    langs: ['en', 'ru', 'de'],
    mask: 'paystree-conditions-{lang}.pdf?1',
  },
  'paystree-plans': {
    type: 'file',
    langs: ['en', 'ru', 'de'],
    mask: 'paystree-plans-{lang}.pdf?1',
  },
  apk: {
    type: 'file',
    langs: ['en'],
    mask: 'app-release.apk',
  },
};

const tryToReturnResult = (funcWithResult: () => string) => {
  let result = '';

  try {
    result = funcWithResult();

    if (!result) {
      return '#';
    }

    // return result
    return result;
  } catch (e: any) {
    console.error(e);
    return '#';
  }
};
const getDoc = (docName: keyof typeof docsInfo, lang: string): string => {
  let currentLang = lang;
  const docInfo = docsInfo[docName];

  const logDocLangIsNotFound = () => {
    devLog.warn(`[getDoc]: ${docName}_${currentLang.toUpperCase()} is not found`);
  };

  if (!docInfo) {
    devLog.warn(`[getDoc]: document with name ${docName} is not found`);

    return '#';
  }

  // check that currentLang includes for that file
  if (!docInfo.langs.includes(currentLang)) {
    [currentLang] = docInfo.langs;
    devLog.warn(
      `[getDoc]: ${docName}_${lang.toUpperCase()} is not found.`,
      `Trying to get in ${currentLang.toUpperCase()}`,
    );
  }

  if (docInfo.type === 'link') {
    const { links } = docInfo;

    if (!links) {
      logDocLangIsNotFound();
      return '#';
    }

    return tryToReturnResult(() => links[currentLang]);
  }

  if (docInfo.type === 'file' || !docInfo.type) {
    const { mask } = docInfo;

    if (!mask) {
      logDocLangIsNotFound();
      return '#';
    }

    return tryToReturnResult(() => {
      const result = mask.replace('{lang}', currentLang);
      return `/docs/${result}`;
    });
  }

  return '#';
};

export const getTrHookGetDoc = () => {
  const { locale } = useI18n({ useScope: 'global' });

  return (docName: keyof typeof docsInfo) => getDoc(docName, locale.value as string);
};

export default getDoc;
