
import { defineComponent } from 'vue';
import { modalsNames } from '@/utils/modals';

import CommonModal from './CommonModal.vue';

export default defineComponent({
  name: 'ModalInfo',
  components: {
    CommonModal,
  },
  setup() {
    const modalName = modalsNames.modalInfo;
    return {
      modalName,
    };
  },
});
