import isDev from './isDev';

const log = (method: 'log' | 'info' | 'warn' | 'error') => (...message: any[]) => {
  if (!isDev) {
    return;
  }

  // eslint-disable-next-line no-console
  console[method](...message);
};

export default {
  log: log('warn'),
  info: log('info'),
  warn: log('warn'),
  error: log('error'),
};
