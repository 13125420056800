
import { defineComponent } from 'vue';
import '@/assets/icons/simple-oton-logo.svg?sprite';
import CommonSpinner from './CommonSpinner.vue';

export default defineComponent({
  name: 'CommonLoader',
  components: { CommonSpinner },
  props: {
    state: String,
    isPlaceholder: Boolean,
    active: Boolean,
    top: Boolean,
  },
});
