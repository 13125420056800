/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable */

import { AxiosResponse } from "axios";

import { pathOr, clone, omit } from "rambda";

import { defineModule } from "direct-vuex";
import { modActionCtx, modGetterCtx } from "@/store/index";
import { api } from "@/utils/apiInstance";

import { State, basicOfficeFetch } from "@/store/storeUtils";
import { INIT, PENDING, FULFILLED } from "@/utils/statuses";
import debounce from "debounce";
import {
  OfficeTransactionItem,
  OfficeTransactionResponse,
} from "@/interfaces/OfficeTransactionsTypes";

export type TransactionResponse = AxiosResponse<{ data: OfficeTransactionResponse }>;
export interface RequestSearchItem {
  field: "t.currency_id" | string;
  operator: "in" | "is";
  value: Array<number | string> | number | string;
}
export interface FetchRequestParams {
  request: {
    cmd: "get";
    limit: 10;
    offset: 0;
    sort: [
      {
        field: "date";
        direction: "desc";
      }
    ];
    search: RequestSearchItem[];
    searchLogic: "OR" | "AND";
  };
}

export interface TransactionsStateInterface {
  data: OfficeTransactionItem[];
  totalProfit: number;
  totalProfitUsdt: number;
  lostProfit: number;
  lostProfitUsdt: number;
  fetchState: State;
  canFetchMore: boolean;
  page: number;
  limit: number;

  filters: {
    timestamp?: {
      start: number;
      end: number;
    };
    search?: {
      [key: string]: RequestSearchItem;
    };
    searchLogic?: "OR" | "AND";
  };
}

const initialState: TransactionsStateInterface = {
  data: [],
  totalProfit: 0,
  totalProfitUsdt: 0,
  lostProfit: 0,
  lostProfitUsdt: 0,
  fetchState: INIT,

  canFetchMore: false,

  page: 1,
  limit: 25,

  filters: {},
};

const officeTransactionsStore = defineModule({
  namespaced: true,
  state: clone(initialState),

  getters: {
    fetchMoreState(...args): State {
      const { state } = getterCtx(args);

      if (state.data.length > 0) {
        return state.fetchState;
      }
      return FULFILLED;
    },
    fetchState(...args): State {
      const { state } = getterCtx(args);

      if (state.data.length <= 0) {
        return state.fetchState;
      }
      return FULFILLED;
    },
  },
  mutations: {
    CLEAR_STORE(state) {
      Object.assign(state, clone(initialState));
    },
    PREPARE_FOR_FIRST_FETCH(state) {
      Object.assign(state, omit(["filters"], initialState));
    },
    SET_STATE(state, { fetchState, data }: { fetchState: State; data: TransactionResponse }) {
      state.fetchState = fetchState;

      if (data) {
        const list = pathOr([], "data.data.records", data);
        const total = pathOr(0, "data.data.total", data);
        const totalProfitValue = pathOr(0, "data.data.total_profit", data);
        const totalProfitUsdtValue = pathOr(0, "data.data.total_profit_USDT", data);
        const lostProfitValue = pathOr(0, "data.data.lost_profit", data);
        const lostProfitUsdtValue = pathOr(0, "data.data.lost_profit_USDT", data);

        const resultList = [...state.data, ...list];

        state.canFetchMore = total > resultList.length;
        state.data = resultList;
        state.totalProfit = totalProfitValue;
        state.totalProfitUsdt = totalProfitUsdtValue;
        state.lostProfit = lostProfitValue;
        state.lostProfitUsdt = lostProfitUsdtValue;
      }
    },
    SET_INIT(state) {
      state.fetchState = INIT;
    },
    SET_FILTERS(state, { filters }: { filters: TransactionsStateInterface["filters"] }) {
      state.filters = filters;
    },
    PREPARE_FOR_FILTERS(state) {
      Object.assign(state, omit(["filters"], initialState));
    },
    SET_PAGE(state, page: number) {
      state.page = page;
    },
  },
  actions: {
    async createReq(ctx) {
      const { commit, state } = actionCtx(ctx);

      const hasDates =
        state.filters.timestamp && state.filters.timestamp.start && state.filters.timestamp.end;

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: "/transaction/listTransactions",
          data: {
            request: JSON.stringify({
              cmd: "get",
              limit: state.limit,
              offset: state.limit * (state.page - 1),
              sort: [
                {
                  field: "date",
                  direction: "desc",
                },
              ],
              search: [
                ...(hasDates
                  ? [
                      {
                        field: "date",
                        value: state.filters.timestamp!.start,
                        operator: "more",
                      },
                      {
                        field: "date",
                        value: state.filters.timestamp!.end,
                        operator: "less",
                      },
                    ]
                  : []),
                ...Object.values(state.filters.search || {}),
              ],
              searchLogic: state.filters?.searchLogic || "AND",
            }),
          },
        },
        setState: commit.SET_STATE,
        callback: (fetchState) => {
          if (fetchState === FULFILLED) {
            commit.SET_PAGE(state.page + 1);
          }
        },
      });
    },

    async fetch(
      ctx,
      props: { fromZero?: boolean; filters?: TransactionsStateInterface["filters"] }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      if (props.fromZero) {
        commit.PREPARE_FOR_FIRST_FETCH();
      }
      if (props.filters) {
        commit.SET_FILTERS({ filters: props.filters });
      }

      await dispatch.createReq();
    },

    changeFilters: (() => {
      const debouncedFetch = debounce((func: any) => {
        func();
      }, 200);

      return (ctx: any, filters: TransactionsStateInterface["filters"]) => {
        const {
          commit,
          //  state,
          dispatch,
        } = actionCtx(ctx);

        // if (state.fetchState === PENDING) {
        //   return;
        // }

        commit.SET_FILTERS({ filters });
        debouncedFetch(() => {
          commit.PREPARE_FOR_FILTERS();
          dispatch.createReq();
        });
      };
    })(),
  },
});

export default officeTransactionsStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, officeTransactionsStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, officeTransactionsStore);
