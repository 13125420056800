/* eslint-disable */

import allowedLangs from './allowedLangs';
import base from './translationsBase.csv';

// [key: string]: [key, ru, en, de, tr][]
const normalizedBase: Array<string[]> = (base as string[][]).map(row =>
  row.map((item) =>
    item
      .replace(/\n/gi, ' ')
      .replace(/\s+/g, ' ')
      .trim(),
  ),
);

const strings: {
  [key: string]: {
    [key: string]: string
  }
} = {};

allowedLangs.forEach((l) => {
  strings[l] = {};
});

normalizedBase.forEach((row) => {
  allowedLangs.forEach((l, index) => {
    const value = row[index + 2]; // const value = row[index + 1];
    if (value) {
      strings[l][row[0]] = value;
    }
  });
});

export default strings;
