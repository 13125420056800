
import { computed, defineComponent, watch } from 'vue';
import modals, { modalsNames } from '@/utils/modals';
import store from '@/store';
import { pathOr } from 'rambda';
import CommonModal from './CommonModal.vue';
import WithLoader from './WithLoader.vue';

export default defineComponent({
  name: 'ModalDefrostQualify',
  components: {
    CommonModal,
    WithLoader,
  },
  setup() {
    const modalName = modalsNames.defrostQualify;
    const userState = computed(() => store.state.userStore.fetchState);
    const userData = computed(() => store.getters.userStore.data);
    const userQualify = computed(() => ({
      qualify: pathOr(0, 'qualify', userData.value),
      qualifyText: pathOr('', 'qualify_text', userData.value),
    }));

    watch(
      () => userQualify.value.qualify,
      (qualify) => {
        if (qualify > 0) {
          modals.defrostQualify.closeModal();
        }
      },
    );

    return {
      modalName,
      userState,
      userQualify,
    };
  },
});
