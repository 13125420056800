/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { AxiosResponse } from 'axios';

import { pathOr, clone, omit } from 'rambda';

import {
  MultiTransactionItemInterface,
  // TransactionsItemInterface,
} from '@/interfaces/transactionsTypes';
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import { basicAdapterFetch, State } from '@/store/storeUtils';
import { INIT, PENDING, FULFILLED } from '@/utils/statuses';
import getAuth from '@/utils/cookies/getAuth';
import debounce from 'debounce';

export type TransactionResponse = AxiosResponse<{ data: MultiTransactionItemInterface }>;

export interface TransactionsStateInterface {
  data: MultiTransactionItemInterface[];
  fetchState: State;
  canFetchMore: boolean;
  page: number;
  limit: number;

  filters: {
    timestamp?: {
      start: number;
      end: number;
    };
    tx_type?: 'withdraw' | 'refill' | '';
    currency?: string;
    type?: string;
  };
}

const initialState: TransactionsStateInterface = {
  data: [],
  fetchState: INIT,

  canFetchMore: false,
  page: 1,
  limit: 25,

  filters: {
    tx_type: '',
    currency: '',
    type: '',
  },
};

const transactionsStore = defineModule({
  namespaced: true,
  state: clone(initialState),

  getters: {
    fetchMoreState(...args): State {
      const { state } = getterCtx(args);

      if (state.data.length > 0) {
        return state.fetchState;
      }
      return FULFILLED;
    },
    fetchState(...args): State {
      const { state } = getterCtx(args);

      if (state.data.length <= 0) {
        return state.fetchState;
      }
      return FULFILLED;
    },
  },
  mutations: {
    CLEAR_STORE(state) {
      Object.assign(state, clone(initialState));
    },
    PREPARE_FOR_FIRST_FETCH(state) {
      Object.assign(state, omit(['filters'], initialState));
    },
    SET_STATE(state, { fetchState, data }: { fetchState: State; data: TransactionResponse }) {
      state.fetchState = fetchState;

      if (data) {
        const list = pathOr([], 'data.data', data);

        state.canFetchMore = list.length >= state.limit;

        state.data = [...state.data, ...list];
      }
    },
    SET_INIT(state) {
      state.fetchState = INIT;
    },
    SET_FILTERS(state, { filters }: { filters: TransactionsStateInterface['filters'] }) {
      state.filters = filters;
    },
    PREPARE_FOR_FILTERS(state) {
      Object.assign(state, clone(omit(['filters'], initialState)));
    },
    SET_PAGE(state, page: number) {
      state.page = page;
    },
  },
  actions: {
    async createReq(ctx) {
      const { commit, state } = actionCtx(ctx);

      await basicAdapterFetch({
        method: api.adapter.sendPost,
        props: {
          url: '/oton/transactions',
          data: {
            token: getAuth(),
            page: state.page,
            limit: state.limit,
            filter: state.filters,
          },
        },
        setState: commit.SET_STATE,
        callback: (fetchState) => {
          if (fetchState === FULFILLED) {
            commit.SET_PAGE(state.page + 1);
          }
        },
      });
    },

    async fetch(ctx, props: { fromZero?: boolean }) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      if (props.fromZero) {
        commit.PREPARE_FOR_FIRST_FETCH();
      }

      await dispatch.createReq();
    },

    changeFilters: (() => {
      const debouncedFetch = debounce((func: any) => {
        func();
      }, 500);

      return (ctx: any, filters: TransactionsStateInterface['filters']) => {
        const {
          commit,
          //  state,
          dispatch,
        } = actionCtx(ctx);

        // if (state.fetchState === PENDING) {
        //   return;
        // }

        commit.SET_FILTERS({ filters });
        debouncedFetch(() => {
          commit.PREPARE_FOR_FILTERS();
          dispatch.createReq();
        });
      };
    })(),
  },
});

export default transactionsStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, transactionsStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, transactionsStore);
