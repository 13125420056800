const decimalAdjust = (type: 'round' |'floor' | 'ceil', value: number, exp?: number) => {
  // Если степень не определена, либо равна нулю...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }

  let value1: number | number[] | string[] = +value;
  const exp1 = +exp;
  // Если значение не является числом, либо степень не является целым числом...
  if (Number.isNaN(value1) || !(typeof exp1 === 'number' && exp1 % 1 === 0)) {
    return NaN;
  }
  // Сдвиг разрядов
  value1 = value1.toString().split('e');
  value1 = Math[type](+`${value1[0]}e${value1[1] ? +value1[1] - exp1 : -exp1}`);
  // Обратный сдвиг
  value1 = value1.toString().split('e');
  return +`${value1[0]}e${value1[1] ? +value1[1] + exp1 : exp1}`;
};

export type Rounding = (value: number, exp?: number) => number;

// Десятичное округление к ближайшему
export const round10: Rounding = (value, exp) => decimalAdjust('round', value, exp);

// Десятичное округление вниз
export const floor10: Rounding = (value, exp) => decimalAdjust('floor', value, exp);

// Десятичное округление вверх
export const ceil10: Rounding = (value, exp) => decimalAdjust('ceil', value, exp);
