

import { defineComponent, computed } from 'vue';
import modals, { modalsNames } from '@/utils/modals';
import store from '@/store';
import CommonModal from './CommonModal.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';

export default defineComponent({
  name: 'ModalConfirmationEmail',
  components: { CommonModal, CommonButtonPrimary },

  setup() {
    const modalName = modalsNames.modalConfirmationEmail;

    const email = computed(() => store.state.userStore.updatedEmail);

    const onClose = () => {
      localStorage.removeItem('updatedEmail');
      modals.modalConfirmationEmail.closeModal();
    };

    return {
      modalName,
      email,
      onClose,
    };
  },
});
