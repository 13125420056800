/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { defineModule } from 'direct-vuex';
import { modActionCtx } from '@/store/index';

export interface ConfirmModalStoreStateInterface {
  isOpen: boolean;
  resolve: any;
}

const initialState: ConfirmModalStoreStateInterface = {
  isOpen: false,
  resolve: undefined,
};

const confirmModalStore = defineModule({
  namespaced: true,
  state: {
    ...initialState,
  } as ConfirmModalStoreStateInterface,

  mutations: {
    OPEN_MODAL(state, resolveAction: ConfirmModalStoreStateInterface['resolve']) {
      state.isOpen = true;
      state.resolve = resolveAction;
    },
    CLOSE_MODAL(state) {
      Object.assign(state, initialState);
    },
  },
  actions: {
    askConfirm(ctx) {
      const { commit } = actionCtx(ctx);

      return new Promise((resolve) => {
        commit.OPEN_MODAL(resolve);
      });
    },

    setAnswer(ctx, answer: boolean) {
      const { commit, state } = actionCtx(ctx);

      if (typeof state.resolve === 'function') {
        state.resolve(answer);
      }

      commit.CLOSE_MODAL();
    },
  },
});

export default confirmModalStore;
// const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, ConfirmModalStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, confirmModalStore);
