/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';

export interface TwoFaStoreStateInterface {
  action?: (fa: string) => void;
  twoFa: string;
}

const twoFaStore = defineModule({
  namespaced: true,
  state: {
    action: undefined,
    twoFa: '',
  } as TwoFaStoreStateInterface,

  getters: {

    isOpen(...args): boolean {
      const { state } = getterCtx(args);

      return !!state.action;
    },
  },
  mutations: {
    SET_ACTION(state, action: TwoFaStoreStateInterface['action']) {
      state.action = action;
    },
    CLEAR(state) {
      state.twoFa = '';
      state.action = undefined;
    },
    CHANGE(state, twoFa: string) {
      state.twoFa = twoFa;
    },
  },
  actions: {
    with2FAData(ctx, action: (fa: string) => void) {
      const { commit } = actionCtx(ctx);
      commit.SET_ACTION(action);
    },

    send(ctx) {
      const { commit, state } = actionCtx(ctx);

      if (state.action && state.twoFa) {
        state.action(state.twoFa);
        commit.CLEAR();
      }
    },

    close(ctx) {
      const { commit } = actionCtx(ctx);

      commit.CLEAR();
    },
  },
});

export default twoFaStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, twoFaStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, twoFaStore);
