/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { path } from 'rambda';
import { TwoAuthCodeItemsInterface } from '@/interfaces/TwoAuthCodeTypes';
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import {
  FetchOfficeState,
  getFetchState,
  getFetchMutations,
  basicOfficeFetch,
} from '@/store/storeUtils';
import {
  PENDING, REJECTED,
} from '@/utils/statuses';

type StateInterface = FetchOfficeState<TwoAuthCodeItemsInterface>;

const TwoAuthCodeStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<StateInterface>(),
  } as StateInterface,

  getters: {
    data(...args): TwoAuthCodeItemsInterface | undefined {
      const { state } = getterCtx(args);

      const result = path('data.data', state.response) as TwoAuthCodeItemsInterface;

      return result;
    },
    secret(...args): TwoAuthCodeItemsInterface | undefined {
      const { state } = getterCtx(args);

      const result = path('data.data.secret', state.response) as TwoAuthCodeItemsInterface;

      return result;
    },
    qr(...args): TwoAuthCodeItemsInterface | undefined {
      const { state } = getterCtx(args);

      const result = path('data.data.qr', state.response) as TwoAuthCodeItemsInterface;

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),
  },
  actions: {

    async fetch(ctx) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/two/index',
          data: {},
        },
        setState: commit.SET_STATE,
        // update state
        callback: (fetchState) => {
          if (fetchState === REJECTED) {
            console.error('Fetch error in twoAuthCodeStore.fetch, status: ', REJECTED);
          }
        },
      });
    },
  },
});

export default TwoAuthCodeStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, TwoAuthCodeStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, TwoAuthCodeStore);
