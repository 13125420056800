

import { defineComponent, reactive, computed } from 'vue';
import { basicOfficeFetch } from '@/store/storeUtils';
import useSWRV, { mutate } from 'swrv';
import { api } from '@/utils/apiInstance';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import { useToast } from 'vue-toastification';
import '@/assets/icons/close-icon.svg?sprite';
import modals, { modalsNames } from '@/utils/modals';
import CommonLoader from '@/components/CommonLoader.vue';
import CommonModal from './CommonModal.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonInput from './CommonInput.vue';

export default defineComponent({
  name: 'modalCreateServer',
  components: {
    CommonModal, CommonButtonPrimary, CommonInput, CommonLoader,
  },
  setup() {
    const modalName = modalsNames.modalCreateServer;

    const toast = useToast();

    const formData = reactive({
      hash: '',
      getWalletState: 'INIT',
      createServerState: 'INIT',
      contractsListState: 'INIT',
      contractsList: [],
      getWallet: { address: '', image: '', count_error: 4 },
      isTryingEnterHash: false,
      createServer: {},
      remainingAttempts: 4,
      errorText: '',
    });

    const paidInfo = [
      {
        title: 'Network',
        value: (list: any) => list.network,
      },
      {
        title: 'Amount',
        value: (list: any) => `${list.amount} ${list.currency}`,
      },
    ];

    const takeHashValue = (value: any) => {
      const hashValue = window.location.hash.split('&').find((item) => item.split('=')[0] === value);
      if (hashValue) {
        return hashValue.split('=')[1];
      }
      return '';
    };

    const opened = () => {
      basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/contracts/getWallet',
          data: { contract_id: Number(takeHashValue('node_contract_id')) },
        },
        setState: ({ fetchState }) => {
          formData.getWalletState = fetchState;
        },
        callback: (fetchState, data) => {
          if (fetchState === 'FULFILLED') {
            formData.getWallet = data?.data.data;
          }
        },
      });
    };

    const fetchContractsList = () => {
      basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/contracts/userContracts',
        },
        setState: ({ fetchState }) => {
          formData.contractsListState = fetchState;
        },
        callback: (fetchState, data) => {
          if (fetchState === 'FULFILLED') {
            formData.contractsList = data?.data.data;
          }
        },
      });
    };

    const prefetchNodesList = () => {
      mutate(
        '/contracts/userContracts',
        api.office.sendPost({ url: '/contracts/userContracts' }),
      );
    };

    const createServer = () => {
      basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/contracts/create',
          data: { contract_id: Number(takeHashValue('node_contract_id')), receiptTxId: formData.hash },
        },
        setState: ({ fetchState }) => {
          formData.createServerState = fetchState;
        },
        callback: (fetchState, data) => {
          if (fetchState === 'FULFILLED') {
            formData.hash = '';
            formData.createServer = data?.data.data;
            if (data?.data.data?.count_error) {
              formData.remainingAttempts = Number(data?.data.data?.count_error);
              formData.hash = '';
            }
            if (data?.data.data?.status === 'OK') {
              toast.success('Waiting for the server to be created. This usually takes about 15 minutes.');
              prefetchNodesList();
              modals.modalCreateServer.closeModal();
            }
            if (data?.data.data.error !== null && data?.data.data?.status !== 'OK') {
              toast.error(data?.data.data.error);
            }
          }
          if (fetchState === 'REJECTED') {
            toast.warning('Something went wrong');
          }
          if (data?.data.code === 402) {
            formData.hash = '';
          }
          setTimeout(() => {
            if (data?.data.data?.status !== 'OK') {
              opened();
            }
            fetchContractsList();
          }, 500);
        },
      });

      formData.isTryingEnterHash = true;
    };

    const contractsList = useSWRV('/contracts/userContracts', null)?.data;
    const takeContractBlockServerPeriod = (list: any) => computed(() => Number(((formData.contractsList.length && formData.contractsList)
      || list || []).find((item: any) => item.id === Number(takeHashValue('node_contract_id')))?.block_server));

    const { d } = useI18n();

    const dateDay = (date: number) => d(String(dayjs.unix(date).format('YYYY-MM-DD')), 'newsDate');
    const dateHour = (date: number) => String(dayjs.unix(date).format('HH:mm'));

    const isShowModal = computed(() => (formData.getWalletState === 'FULFILLED' && formData.createServerState !== 'PENDING') || formData.createServerState === 'FULFILLED');

    const closeModal = () => {
      formData.hash = '';
      formData.getWalletState = 'INIT';
      formData.createServerState = 'INIT';
      formData.getWallet = { address: '', image: '', count_error: 4 };
      formData.createServer = {};
      formData.remainingAttempts = 4;
      formData.isTryingEnterHash = false;
    };

    const timeNow = computed(() => Number(Date.now()) / 1000);

    return {
      timeNow,
      takeContractBlockServerPeriod,
      contractsList,
      closeModal,
      createServer,
      opened,
      modalName,
      formData,
      paidInfo,
      dateDay,
      dateHour,
      isShowModal,
    };
  },
});
