
import store from '@/store';

import { defineComponent, computed } from 'vue';
import { modalsNames } from '@/utils/modals';
import CommonModal from './CommonModal.vue';

export default defineComponent({
  name: 'ModalProductDetails',
  components: { CommonModal },

  setup() {
    const modalName = modalsNames.modalProductDetails;

    const hash = computed(() => store.getters.routeStore.hash);

    const data = computed(() => ({
      category: hash.value.category || '',
      title: hash.value.title || '',
      price: hash.value.price || '',
      desc: hash.value.desc || '',
    }));

    const title = computed(() => `${data.value.category} ${data.value.title}`.trim());

    return {
      modalName,
      data,
      title,
    };
  },
});
