/* eslint-disable */
import { reduce, keys } from "rambda";
import { closeModal, openModal } from "./setModal";

const getOneModalData = (name: string) => ({
  [name]: {
    name,
    openModal: (rest?: { [key: string]: any }) => openModal(name, rest),
    closeModal,
  },
});

export const modalsNames = {
  requestOton: "requestOton",
  requestOtonAccept: "requestOtonAccept",
  requestOtonQr: "requestOtonQr",
  transferOton: "transferOton",
  transferInfoOton: "transferInfoOton",
  trnInfo: "trnInfo",
  transferBtc: "transferBtc",
  transferBtcAccept: "transferBtcAccept",
  transferUsdtAccept: "transferUsdtAccept",
  withdrawBtc: "withdrawBtc",
  withdrawBtcAccept: "withdrawBtcAccept",
  modalWithdrawEuro: "modalWithdrawEuro",
  modalWithdrawEuroAccept: "modalWithdrawEuroAccept",
  modalWithdrawOton: "modalWithdrawOton",
  modalWithdrawOtonAccept: "modalWithdrawOtonAccept",
  transferUsdt: "transferUsdt",
  withdrawUsdt: "withdrawUsdt",
  withdrawUsdtAccept: "withdrawUsdtAccept",
  modalRequests: "modalRequests",
  modalRequestsAccept: "modalRequestsAccept",
  contacts: "contacts",
  defrostQualify: "defrostQualify",
  modalInfo: "modalInfo",
  modalListOfCountries: "modalListOfCountries",
  modalPaymentSuccess: "modalPaymentSuccess",
  modalPaymentFail: "modalPaymentFail",
  modalWithdrawFlc: "modalWithdrawFlc",
  modalWithdrawFlcAccept: "modalWithdrawFlcAccept",
  modalNewContract: "modalNewContract",
  modalTopUp: "modalTopUp",
  modalCreateServer: "modalCreateServer",
  modalOpenSupport: "modalOpenSupport",
  modalNewEmail: "modalNewEmail",
  modalConfirmationEmail: "modalConfirmationEmail",
  modalNewAddress: "modalNewAddress",
  modalProductDetails: "modalProductDetails",
};

type modalsType = {
  [key in keyof typeof modalsNames]: {
    name: keyof typeof modalsNames;
    openModal: (rest?: { [key: string]: any }) => void;
    closeModal: typeof closeModal;
  };
};

const modals = reduce<keyof typeof modalsNames, modalsType>(
  (prev, current) => ({ ...prev, ...getOneModalData(current) }),
  {} as modalsType,
  keys(modalsNames)
);

export default modals;
