import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fe402bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "NoData"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonLoader = _resolveComponent("CommonLoader")!
  const _component_NoData = _resolveComponent("NoData")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.state === _ctx.$status.PENDING || _ctx.state === _ctx.$status.INIT || _ctx.active)
      ? (_openBlock(), _createBlock(_component_CommonLoader, {
          key: 0,
          state: _ctx.state,
          isPlaceholder: _ctx.isPlaceholder,
          active: _ctx.active,
          top: _ctx.top
        }, null, 8, ["state", "isPlaceholder", "active", "top"]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: _ctx.transitionName }, {
      default: _withCtx(() => [
        (_ctx.state === _ctx.$status.FULFILLED)
          ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["name"]),
    (_ctx.state === _ctx.$status.REJECTED)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_NoData, { datatError: true })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}