
import store from '@/store';
import { computed, defineComponent, PropType } from 'vue';
import modals, { modalsNames } from '@/utils/modals';

import '@/assets/icons/close-icon.svg?sprite';

export default defineComponent({
  name: 'CommonModal',
  components: {},
  emits: ['closeModal'],
  props: {
    visible: Boolean,
    name: {
      type: String as PropType<keyof typeof modalsNames>,
      required: false,
    },
    title: {
      type: String,
      default: ' ',
    },
    width: {
      type: Number,
      default: 600,
    },
    hardwareWalletContent: {
      type: Boolean,
      default: false,
    },
    isTopUpModal: Boolean,
    isModalNewContract: Boolean,
    isModalCreateServer: Boolean,
    isProductDetails: Boolean,
  },
  setup(props, ctx) {
    const currentModalOpen = computed(() => store.getters.routeStore.currentModalOpen);
    const isOpen = computed(() => currentModalOpen.value === props.name);
    const closeHandler = () => {
      if (typeof props.name === 'string' && modals[props.name]) {
        modals[props.name].closeModal();
      }
      ctx.emit('closeModal');
    };

    return {
      currentModalOpen,
      isOpen,
      closeHandler,
    };
  },
});
