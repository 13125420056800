/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { AxiosResponse } from 'axios';
import { pathOr } from 'rambda';
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import {
  FetchStateInterface,
  getFetchState,
  getFetchMutations,
  basicAdapterFetch,
} from '@/store/storeUtils';
import { INIT, PENDING } from '@/utils/statuses';
import getAuth from '@/utils/cookies/getAuth';

export type OtonUsdtResponse = AxiosResponse<{ data: number }>;

const otonUsdtStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<OtonUsdtResponse>(),
  } as FetchStateInterface<OtonUsdtResponse>,

  getters: {
    data(...args): number {
      const { state } = getterCtx(args);

      const result = pathOr(0, 'data.data', state.response) as number;

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),
    SET_INIT(state) {
      state.fetchState = INIT;
    },
  },
  actions: {
    async fetch(ctx) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicAdapterFetch({
        method: api.adapter.sendPost,
        props: {
          url: '/rate/lp/oton-usdt',
          data: {
            drivers: {
              oton: {
                token: getAuth(),
              },
            },
          },
        },
        setState: commit.SET_STATE,
      });
    },
  },
});

export default otonUsdtStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, otonUsdtStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, otonUsdtStore);
