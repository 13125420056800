/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { AxiosResponse } from 'axios';
import { groupWith, path } from 'rambda';
import Decimal from 'decimal.js';
import { DexSwapItemInterface } from '@/interfaces/transactionsTypes';
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';
import {
  FetchStateInterface,
  getFetchState,
  getFetchMutations,
  basicAdapterFetch,
} from '@/store/storeUtils';
import { INIT, PENDING } from '@/utils/statuses';
import getAuth from '@/utils/cookies/getAuth';

export type DexSwapResponse = AxiosResponse<{ data: DexSwapItemInterface[] }>;

export interface MappedDexSwap {
  txid: string;
  method: string;
  action: string;
  firstToken: {
    total: string;
    symbol: string;
  };
  secondToken: {
    total: string;
    symbol: string;
  };
  account: string;
  timestamp: number;
}

const otonDexSwapsStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<DexSwapResponse>(),
  } as FetchStateInterface<DexSwapResponse>,

  getters: {
    data(...args): DexSwapItemInterface[] | undefined {
      const { state } = getterCtx(args);

      const result = path('data.data', state.response) as DexSwapItemInterface[];

      return result;
    },
    mappedData(...args): MappedDexSwap[] {
      const { getters, rootGetters } = getterCtx(args);
      const { ratesSettings } = rootGetters.exchangeRatesStore;
      const data = [...getters.data || []].reverse();

      const transactoins = groupWith((a, b) => a.txid === b.txid, data);

      const mapSymbols = {
        'BSC-USD': 'USDT',
        OTON: 'OTON',
      };
      const separatorByMethod = {
        swap: 'for',
        add: 'and',
        remove: 'and',
      };
      const getTotal = (total: string, decimal: number, precision: number | undefined) => {
        const result = new Decimal(total);

        return result.div(10 ** decimal).toFixed(precision || 4);
      };

      return transactoins.filter((item) => !!item[0] && !!item[1]).map((item) => {
        let [a, b] = item;

        if (a.type === 'withdraw') {
          [b, a] = item;
        }

        const { method } = a;
        const separator = separatorByMethod[method];
        const aSymbol = mapSymbols[a.token.symbol];
        const bSymbol = mapSymbols[b.token.symbol];
        const aSymbolLower = aSymbol.toLowerCase() as keyof typeof ratesSettings;
        const bSymbolLower = bSymbol.toLowerCase() as keyof typeof ratesSettings;
        const aPrecision = ratesSettings[aSymbolLower].precision;
        const bPrecision = ratesSettings[bSymbolLower].precision;

        return {
          txid: a.txid,
          method,
          action: `${method} ${aSymbol} ${separator} ${bSymbol}`,
          firstToken: {
            total: getTotal(a.value, a.token.decimal, aPrecision),
            symbol: aSymbol,
          },
          secondToken: {
            total: getTotal(b.value, b.token.decimal, bPrecision),
            symbol: bSymbol,
          },
          account: a.type === 'withdraw' ? a.to : a.from,
          timestamp: a.timestamp,
        };
      });
    },
    swaps(...args): MappedDexSwap[] {
      const { getters } = getterCtx(args);

      return getters.mappedData.filter(({ method }) => method === 'swap');
    },
  },
  mutations: {
    ...getFetchMutations(),
    SET_INIT(state) {
      state.fetchState = INIT;
    },
  },
  actions: {
    async fetch(ctx) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicAdapterFetch({
        method: api.adapter.sendPost,
        props: {
          url: '/lp/oton-usdt/transactions',
          data: {
            drivers: {
              oton: {
                token: getAuth(),
              },
            },
          },
        },
        setState: commit.SET_STATE,
        // update state
        callback: () => {
          // console.info({ fetchState });
        },
      });
    },
  },
});

export default otonDexSwapsStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, otonDexSwapsStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, otonDexSwapsStore);
