/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { defineModule } from 'direct-vuex';
// import { modActionCtx } from '@/store/index';

export interface UserEmailStoreInterface {
  hidden: boolean;

}

const userEmailStore = defineModule({
  namespaced: true,
  state: {
    hidden: window.localStorage.getItem('emailIsHidden') === 'true',
  } as UserEmailStoreInterface,

  getters: {},
  mutations: {
    TOGGLE(state) {
      const newValue = !state.hidden;

      window.localStorage.setItem('emailIsHidden', String(newValue));

      state.hidden = newValue;
    },

  },
  actions: {

  },
});

export default userEmailStore;
// const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, userEmailStore);
// const actionCtx = (ctx: any) => modActionCtx(ctx, userEmailStore);
