/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { modGetterCtx, modActionCtx } from '@/store/index';
import { defineModule } from 'direct-vuex';
import {
  clone, assocPath, pathOr, hasPath,
} from 'rambda';
import { UserContactItemInterface } from '@/interfaces/userContactsTypes';

import modals, { modalsNames } from '@/utils/modals';

export interface FlowAddress extends Omit<UserContactItemInterface, 'id'> {
  withSaveToContacts: '0' | '1';
  sum: number;
}
export interface FlowContact {
  contactId: number | string;
  sum: number;
}
export interface TransactionInfoModalState {
  flows: {
    address: FlowAddress;
    contact: FlowContact;
  };
}

const initialState: TransactionInfoModalState = {
  flows: {
    address: {
      withSaveToContacts: '0',
      address: '',
      btcAddress: '',

      name: '',
      email: '',
      telegram: '',

      sum: 0,
    },
    contact: {
      contactId: 0,
      sum: 0,
    },
  },
};

const transferOtonFlowStore = defineModule({
  namespaced: true,
  state: clone(initialState),

  getters: {
    isOpen(...args): boolean {
      const { rootGetters } = getterCtx(args);

      return rootGetters.routeStore.currentModalOpen === modalsNames.transferOton;
    },
    flow(...args): 'address' | 'contact' {
      const { rootGetters } = getterCtx(args);

      const flows = { address: 'address', contact: 'contact' };
      const curretFlow = rootGetters.routeStore.hash.flow;

      return pathOr('address', [String(curretFlow)], flows);
    },
  },
  mutations: {
    CLOSE_MODAL(state) {
      modals.transferOton.closeModal();
      Object.assign(state, clone(initialState));
    },
    CHANGE_STORE(state, { path, value }: { path: string[]; value: any }) {
      Object.assign(state, assocPath(path, value, state));
    },
    SET_DATA(
      state,
      { data, flow }: { data: FlowAddress | FlowContact; flow: 'address' | 'contact' },
    ) {
      if (flow === 'contact') {
        state.flows[flow] = data as FlowContact;
      } else {
        state.flows[flow] = data as FlowAddress;
      }
    },
  },
  actions: {
    openModal(ctx, payload?: { data: FlowAddress | FlowContact }) {
      const { commit } = actionCtx(ctx);

      commit.CLOSE_MODAL();

      if (typeof payload === 'object') {
        const isAdressFlow = hasPath(['data', 'withSaveToContacts'], payload);
        const isContactFlow = hasPath(['data', 'withSaveToContacts'], payload);

        if (isAdressFlow || isContactFlow) {
          const flow = isAdressFlow ? 'address' : 'contact';
          commit.SET_DATA({ data: payload.data, flow });
          modals.transferOton.openModal({ flow });

          return;
        }
      }

      modals.transferOton.openModal();
    },

    closeModal(ctx) {
      const { commit } = actionCtx(ctx);

      commit.CLOSE_MODAL();
    },
  },
});

export default transferOtonFlowStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, transferOtonFlowStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, transferOtonFlowStore);
