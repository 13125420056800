/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { AxiosResponse } from 'axios';

import { pathOr, clone, omit } from 'rambda';

import {
  StatisticsBinaryPointsInterface,
  StatisticsBinaryMatchInterface,
} from '@/interfaces/statisticsTypes';
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import { basicOfficeFetch, State } from '@/store/storeUtils';
import { INIT, PENDING, FULFILLED } from '@/utils/statuses';
import debounce from 'debounce';

export type BinaryPointsResponse = AxiosResponse<{ data: StatisticsBinaryPointsInterface }>;
export type BinaryMatchResponse = AxiosResponse<{ data: StatisticsBinaryMatchInterface }>;

export interface StatisticsStateInterface {
  binaryPointsData: StatisticsBinaryPointsInterface;
  binaryPointsFetchState: State;

  binaryMatchData: StatisticsBinaryMatchInterface;
  binaryMatchFetchState: State;

  binaryMatchFilters: {
    timestamp?: {
      start: number;
      end: number;
    };
  };
  binaryPointsFilters: {
    timestamp?: {
      start: number;
      end: number;
    };
  };
}

const initialState: StatisticsStateInterface = {
  binaryPointsData: {},
  binaryPointsFetchState: INIT,

  binaryMatchData: {},
  binaryMatchFetchState: INIT,

  binaryMatchFilters: {},
  binaryPointsFilters: {},
};

const statisticsStore = defineModule({
  namespaced: true,
  state: clone(initialState),

  // getters: {
  //   fetchState(...args): State {
  //     const { state } = getterCtx(args);

  //     if (state.binaryPointsData.length <= 0) {
  //       return state.binaryPointsFetchState;
  //     }
  //     return FULFILLED;
  //   },
  // },
  mutations: {
    CLEAR_STORE(state) {
      Object.assign(state, clone(initialState));
    },
    SET_BINARY_POINTS_STATE(state, { fetchState, data }: { fetchState: State; data: BinaryPointsResponse }) {
      state.binaryPointsFetchState = fetchState;

      if (data) {
        const list = pathOr([], 'data.data', data) as StatisticsBinaryPointsInterface;

        state.binaryPointsData = list;
      }
    },
    SET_BINARY_POINTS_INIT(state) {
      state.binaryPointsFetchState = INIT;
    },
    SET_BINARY_MATCH_STATE(state, { fetchState, data }: { fetchState: State; data: BinaryMatchResponse }) {
      state.binaryMatchFetchState = fetchState;
      console.error(data);
      if (data) {
        console.error(data);
        const list = pathOr([], 'data.data', data) as StatisticsBinaryMatchInterface;

        state.binaryMatchData = list;
      }
    },
    SET_BINARY_MATCH_INIT(state) {
      state.binaryMatchFetchState = INIT;
    },
    SET_BINARY_MATCH_FILTERS(state, { filters }: { filters: StatisticsStateInterface['binaryMatchFilters'] }) {
      state.binaryMatchFilters = filters;
    },
    CLEAR_BINARY_MATCH_FILTERS(state) {
      state.binaryMatchFilters = {};
    },
    SET_BINARY_POINTS_FILTERS(state, { filters }: { filters: StatisticsStateInterface['binaryPointsFilters'] }) {
      state.binaryPointsFilters = filters;
    },
    CLEAR_BINARY_POINTS_FILTERS(state) {
      state.binaryPointsFilters = {};
    },
  },
  actions: {
    async createBinaryPointsReq(ctx) {
      const { commit, state } = actionCtx(ctx);

      const hasDates = state.binaryPointsFilters.timestamp && state.binaryPointsFilters.timestamp.start && state.binaryPointsFilters.timestamp.end;

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/statistics',
          data: {
            ...(hasDates ? {
              from: state.binaryPointsFilters.timestamp?.start,
              to: state.binaryPointsFilters.timestamp?.end,
            } : {}),
          },
        },
        setState: commit.SET_BINARY_POINTS_STATE,
      });
    },
    async fetchBinaryPoints(ctx) {
      const { state, dispatch } = actionCtx(ctx);

      if (state.binaryPointsFetchState === PENDING) {
        return;
      }

      await dispatch.createBinaryPointsReq();
    },

    async createBinaryMatchReq(ctx) {
      const { commit, state } = actionCtx(ctx);

      const hasDates = state.binaryMatchFilters.timestamp && state.binaryMatchFilters.timestamp.start && state.binaryMatchFilters.timestamp.end;

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/statistics/find',
          data: {
            ...(hasDates ? {
              from: state.binaryMatchFilters.timestamp?.start,
              to: state.binaryMatchFilters.timestamp?.end,
            } : {}),
          },
        },
        setState: commit.SET_BINARY_MATCH_STATE,
      });
    },

    async fetchBinaryMatch(ctx) {
      const { state, dispatch } = actionCtx(ctx);

      if (state.binaryMatchFetchState === PENDING) {
        return;
      }

      await dispatch.createBinaryMatchReq();
    },

    changeBinaryMatchFilters: (() => {
      const debouncedFetch = debounce((func: any) => {
        func();
      }, 200);

      return (ctx: any, filters: StatisticsStateInterface['binaryMatchFilters']) => {
        const {
          commit,
          //  state,
          dispatch,
        } = actionCtx(ctx);

        // if (state.fetchState === PENDING) {
        //   return;
        // }

        commit.SET_BINARY_MATCH_FILTERS({ filters });
        debouncedFetch(() => {
          dispatch.fetchBinaryMatch();
        });
      };
    })(),

    changeBinaryPointsFilters: (() => {
      const debouncedFetch = debounce((func: any) => {
        func();
      }, 200);

      return (ctx: any, filters: StatisticsStateInterface['binaryPointsFilters']) => {
        const {
          commit,
          //  state,
          dispatch,
        } = actionCtx(ctx);

        // if (state.fetchState === PENDING) {
        //   return;
        // }

        commit.SET_BINARY_POINTS_FILTERS({ filters });
        debouncedFetch(() => {
          dispatch.fetchBinaryPoints();
        });
      };
    })(),
  },
});

export default statisticsStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, statisticsStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, statisticsStore);
