
import store from '@/store';
import { computed, defineComponent } from 'vue';
import modals, { modalsNames } from '@/utils/modals';
import routeHash from '@/utils/routeHash';
import { pathOr } from 'rambda';
import CommonInput from './CommonInput.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonModal from './CommonModal.vue';

export default defineComponent({
  name: 'ModalWithdrawFlc',
  components: {
    CommonModal,
    CommonInput,
    CommonButtonPrimary,
  },
  setup() {
    const modalName = modalsNames.modalWithdrawFlc;

    const balanceData = computed(() => store.getters.userDashboardStore.data);

    const userData = computed(() => store.getters.userStore.data);
    const bep20 = computed(() => pathOr('', ['bep20'], userData.value));
    const availableBalance = computed(() => balanceData.value?.fc_balance);

    const hash = computed(() => store.getters.routeStore.hash);
    const data = computed(() => ({
      bep20: bep20.value || '',

      sum: hash.value.sum ? String(hash.value.sum) : '',
    }));

    const isValidSum = computed(() => {
      const sum = +data.value.sum || 0;

      return (sum <= (availableBalance.value || 0) && sum >= 5000000);
    });

    const form = computed(() => ({
      bep20:
      {
        type: 'string',
        label: 'BEP20-address',
        name: 'bep20',
      },
    }));

    const onChange = (name: string, value: string) => routeHash.replace({ [name]: value });
    const onSubmit = () => {
      if (!bep20.value) {
        return;
      }

      modals.modalWithdrawFlcAccept.openModal(data.value);
    };

    return {
      modalName,
      onChange,
      onSubmit,
      availableBalance,
      data,
      form,
      isValidSum,
      bep20,
    };
  },
});
