

import store from '@/store';
import {
  computed, defineComponent,
} from 'vue';
import '@/assets/icons/close-icon.svg?sprite';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonButtonSecondary from './CommonButtonSecondary.vue';
import CommonModal from './CommonModal.vue';

export default defineComponent({
  name: 'ModalConfirm',
  components: {
    CommonButtonSecondary,
    CommonButtonPrimary,
    CommonModal,
  },
  setup() {
    const isOpen = computed(() => store.state.confirmModalStore.isOpen);

    const onCancel = () => store.dispatch.confirmModalStore.setAnswer(false);
    const onAccept = () => store.dispatch.confirmModalStore.setAnswer(true);

    return {
      isOpen,
      onCancel,
      onAccept,
    };
  },
});
