import { pathOr, path } from 'rambda';
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { UserFrozenOtonResponseInterface } from '@/interfaces/userFrozenOtonTypes';
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import {
  FetchOfficeState,
  getFetchState,
  getFetchMutations,
  basicOfficeFetch,
} from '@/store/storeUtils';
import { PENDING, REJECTED, FULFILLED } from '@/utils/statuses';

interface StateInterface extends FetchOfficeState<UserFrozenOtonResponseInterface> {
  updatersId?: number[] ;
}

const userFrozenOtonStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<StateInterface>(),
    updatersId: undefined,
  } as StateInterface,

  getters: {
    data(...args): UserFrozenOtonResponseInterface | undefined {
      const { state } = getterCtx(args);

      const result = path('data.data', state.response) as UserFrozenOtonResponseInterface;

      return result;
    },
    walletIsBought(...args): boolean {
      const { state } = getterCtx(args);

      const hasBuyDate = path('data.data.5yDate', state.response) as number;
      const balance4MoreThanZero = Number(pathOr(0, 'data.data.balance_4', state.response) as number) > 0;

      const result = !!hasBuyDate && !!balance4MoreThanZero;

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),

    SET_UPDATERS(state, updatersId) {
      state.updatersId = updatersId;
    },
    UPDATE_LIMITS(state, payload: {
      '2yWithdrawLimit'?: number;
      '5yWithdrawLimit'?: number;
      totalWithdrawLimit?: number;
      balance_4?: number;
      balance_5?: number;
    }) {
      const { data } = state.response!.data;

      const newData = {
        ...state.response!.data.data,
        ...payload,
      };

      state.response!.data.data = newData;

      data.totalWithdrawLimit = newData['2yWithdrawLimit'] + newData['5yWithdrawLimit'];
    },
    CLEAR_UPDATERS(state) {
      if (state.updatersId) {
        state.updatersId.forEach(window.clearInterval);
      }
    },
  },
  actions: {
    createUpdater(ctx) {
      const { commit, state, rootGetters } = actionCtx(ctx);

      const isFetched = state.response
      && state.response.data
      && state.response.data.data
      && state.response.data.data['2yDate'];

      if (!isFetched) {
        return;
      }

      const isBuyed = !!state.response!.data.data['5yDate'];

      const calc2yLimit = () => {
        const { data } = state.response!.data;
        const qualified = pathOr(0, 'qualify', rootGetters.userStore.data) > 0;

        const result = {
          '2yWithdrawLimit': data['2yWithdrawLimit'],
          totalWithdrawLimit: data.totalWithdrawLimit,
          balance_3: data.balance_3,
          balance_5: data.balance_5,
        };

        if (!qualified) {
          return result;
        }

        result['2yWithdrawLimit'] = data['2yWithdrawLimit'] + data['2y10secChange'];
        result.totalWithdrawLimit = data.totalWithdrawLimit + data['2y10secChange'];
        result.balance_3 = data.balance_3 + data['2y10secChange'];
        result.balance_5 = data.balance_5 - data['2y10secChange'];

        return result;
      };
      const calc5yLimit = (half?: boolean) => {
        const { data } = state.response!.data;

        let changeSum = data['5y10secChange'];
        if (half) {
          changeSum /= 2;
        }

        const result = {
          '5yWithdrawLimit': data['5yWithdrawLimit'],
          totalWithdrawLimit: data.totalWithdrawLimit,
          balance_3: data.balance_3,
          balance_4: data.balance_4,
        };

        result['5yWithdrawLimit'] = data['5yWithdrawLimit'] + changeSum;
        result.totalWithdrawLimit = data.totalWithdrawLimit + changeSum;
        result.balance_3 = data.balance_3 + changeSum;

        result.balance_4 = data.balance_4 - changeSum;

        return result;
      };

      const update5y = (half?: boolean) => {
        if (isFetched && isBuyed) {
          commit.UPDATE_LIMITS(calc5yLimit(half));
        }
      };
      const update2y = () => {
        if (isFetched) {
          commit.UPDATE_LIMITS(calc2yLimit());
        }
      };

      const updater2yId = window.setInterval(update2y, 10000);
      let HWUpdaterId: number;

      commit.SET_UPDATERS([updater2yId]);

      window.setTimeout(() => {
        update5y(true);

        HWUpdaterId = window.setInterval(update5y, 10000);

        commit.SET_UPDATERS([updater2yId, HWUpdaterId]);
      }, 5000);
    },

    async fetch(ctx) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      commit.CLEAR_UPDATERS();

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/hw/marketplace/info',
          data: {},
        },
        setState: commit.SET_STATE,
        // update state
        callback: (fetchState) => {
          if (fetchState === FULFILLED) {
            dispatch.createUpdater();
          }
          if (fetchState === REJECTED) {
            console.error('Fetch error in userFrozenOtonStore.fetch, status: ', REJECTED);
          }
        },
      });
    },
  },
});

export default userFrozenOtonStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, userFrozenOtonStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, userFrozenOtonStore);
