/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { defineModule } from 'direct-vuex';
import store, { modActionCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import { basicOfficeFetch, State } from '@/store/storeUtils';
import {
  INIT, PENDING, FULFILLED, REJECTED,
} from '@/utils/statuses';

interface withdrawFlcStoreState {
  state: State;
  data: any;
  isError: boolean;
}

const withdrawFlcStore = defineModule({
  namespaced: true,
  state: {
    state: INIT,
    data: '',
    isError: false,
  } as withdrawFlcStoreState,

  getters: {},
  mutations: {
    SET_STATE(state, { fetchState, data }: { fetchState: State; data?: any }) {
      state.state = fetchState;

      if (fetchState === INIT || fetchState === FULFILLED) {
        state.data = '';
      }
      if (data) {
        state.data = data;
      }
    },
    SET_IS_ERROR(state, value) {
      state.isError = value;
    },
  },
  actions: {
    fetch(
      ctx,
      payload: {
        callback?: any;
        amount: number | string;
        destination?: string;
      },
    ) {
      const { commit, state, rootDispatch } = actionCtx(ctx);

      if (state.state === PENDING) {
        return;
      }

      if (store.getters.userStore.userWith2Fa) {
        rootDispatch.twoFaStore.with2FAData((two) => {
          basicOfficeFetch({
            method: api.office.sendPost,
            props: {
              url: '/transaction/withdraw',
              data: {
                amount: payload.amount,
                destination: payload.destination,
                two,
              },
            },
            setState: commit.SET_STATE,
            callback: (fetchState, data) => {
              if (fetchState === FULFILLED) {
                rootDispatch.userStore.fetch();
                rootDispatch.userDashboardStore.fetch();
                if (data?.data?.message && data?.data?.message[0]?.header === 'Error') {
                  commit.SET_IS_ERROR(true);
                } else {
                  commit.SET_IS_ERROR(false);
                  commit.SET_STATE({ fetchState: INIT });
                }
              } else if (fetchState === REJECTED) {
                commit.SET_STATE({ fetchState: REJECTED });
              }
              if (typeof payload.callback === 'function') {
                payload.callback(fetchState);
              }
            },
          });
        });
      } else {
        basicOfficeFetch({
          method: api.office.sendPost,
          props: {
            url: '/transaction/withdraw',
            data: {
              amount: payload.amount,
              destination: payload.destination,
            },
          },
          setState: commit.SET_STATE,
          callback: (fetchState, data) => {
            if (fetchState === FULFILLED) {
              rootDispatch.userStore.fetch();
              rootDispatch.userDashboardStore.fetch();
              if (data?.data?.message && data?.data?.message[0]?.header === 'Error') {
                commit.SET_IS_ERROR(true);
              } else {
                commit.SET_STATE({ fetchState: INIT });
              }
            } else if (fetchState === REJECTED) {
              commit.SET_STATE({ fetchState: REJECTED });
            }
            if (typeof payload.callback === 'function') {
              payload.callback(fetchState);
            }
          },
        });
      }
    },
  },
});

export default withdrawFlcStore;
// const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, withdrawFlcStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, withdrawFlcStore);
