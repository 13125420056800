
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'CommonInput',
  components: {},

  emits: ['update:modelValue', 'keydown'],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: [String, Number] as PropType<string | number>,
      required: true,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    height: {
      type: Number,
      required: false,
    },
    maxLength: {
      type: Number,
      required: false,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorLabel: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String as PropType<'sm' | 'md' | 'xl'>,
      required: false,
      default: 'md',
    },
    paddingLeft: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: String,
    },
    mask: {
      type: [String, Object],
      // default: () => ({ mask: 'Z*', tokens: { Z: { pattern: /[\s\S]/ } } }),
      default: () => (null),
    },
    fontWeight: {
      type: [Number, String],
    },
    autocomplete: String,
    inputPrefixClass: String,
    inputSuffixClass: String,
  },
  methods: {
    updateInput(e: any) {
      this.$emit('update:modelValue', e.target.value);
    },
    keydown(e: any) {
      this.$emit('keydown', e);
    },
  },
});
