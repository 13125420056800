

import { defineComponent } from 'vue';
import '@/assets/icons/close-icon.svg?sprite';
import modals, { modalsNames } from '@/utils/modals';
import CommonModal from './CommonModal.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';

export default defineComponent({
  name: 'ModalPaymentSuccess',
  components: { CommonModal, CommonButtonPrimary },
  setup() {
    const modalName = modalsNames.modalPaymentSuccess;

    const close = modals.modalPaymentSuccess.closeModal;

    return {
      modalName,
      close,
    };
  },
});
