
import { defineComponent, reactive, computed } from 'vue';
import useSWRV from 'swrv';
import { api } from '@/utils/apiInstance';
import { basicOfficeFetch } from '@/store/storeUtils';
import store from '@/store';
import '@/assets/icons/close-icon.svg?sprite';
import { modalsNames } from '@/utils/modals';
import { useToast } from 'vue-toastification';
import dividingIntoDigits from '@/utils/dividingIntoDigits';
import CommonModal from './CommonModal.vue';
import CommonInput from './CommonInput.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';

export default defineComponent({
  name: 'ModalNewContract',
  components: { CommonModal, CommonInput, CommonButtonPrimary },
  setup() {
    const modalName = modalsNames.modalNewContract;

    const toast = useToast();

    const formData = reactive({
      pickedValue: 24,
      amount: undefined,
      state: 'INIT',
      isStake: false,
    });

    const periodsList = [24, 36, 48, 60];

    const balanceData = computed(() => store.getters.userDashboardStore.data);

    const oneFlcInUsd = computed(() => balanceData.value?.flcToUsdtRate || 0);

    const oneUsdInFlc = computed(() => 1 / Number(oneFlcInUsd.value));

    const sumInUsd = computed(() => Number(oneFlcInUsd.value) * Number(formData.amount || 0));
    const sumInFlc = computed(() => Number(oneUsdInFlc.value) * Number(formData.amount || 0));

    const takeHashValue = (value: any) => {
      const hashValue = window.location.hash
        .split('&')
        .find((item) => item.split('=')[0] === value);
      if (hashValue) {
        return hashValue.split('=')[1];
      }
      return '';
    };

    const opened = () => {
      formData.isStake = Boolean(takeHashValue('isStake'));
    };

    const nodeContractAmount = (nodesList: any) => computed(() => (nodesList || []).find((item: any) => item.id === Number(takeHashValue('id'))));
    const isNodeContractAmountMoreInputAmount = (nodesList: any) => computed(
      () => Number(nodeContractAmount(nodesList).value?.max_amount_per_node)
        < Number(String(formData.amount).replace(',', '.') || 0),
    );
    const isNodeContractAmountLessInputAmount = (nodesList: any) => computed(
      () => formData.amount !== undefined
        && Number(nodeContractAmount(nodesList).value?.min_amount)
        > Number(String(formData.amount).replace(',', '.') || 0),
    );

    const findContract = (list: any) => computed(() => (list || []).find((item: any) => item.period === formData.pickedValue));
    const isContractAmountMoreInputAmount = (list: any) => computed(
      () => findContract(list).value?.sum_amount < Number(String(formData.amount).replace(',', '.')),
    );

    const findUserNode = (list: any) => computed(() => (list || []).find((item: any) => item.id === Number(takeHashValue('id'))));
    const isAmountMoreContractsLimit = (userNodes: any) => computed(
      () => findUserNode(userNodes).value?.per_node_limit
        < Number(String(formData.amount).replace(',', '.')),
    );

    const isNodeAmountMoreInputAmount = (nodesList: any) => computed(
      () => (nodesList || []).find((item: any) => item.id === Number(takeHashValue('id')))
        ?.max_amount_limit < Number(formData.amount || 0),
    );

    const isErrorInput = (list: any, nodesList: any, userNodes: any) => computed(
      () => isContractAmountMoreInputAmount(list).value
        || isNodeAmountMoreInputAmount(nodesList).value
        || isNodeContractAmountMoreInputAmount(nodesList).value
        || isAmountMoreContractsLimit(userNodes).value,
    );
    const contractId = (list: any) => computed(() => (list || []).find((item: any) => item.period === formData.pickedValue)?.id);

    const errorForStake = computed(() => formData.isStake && sumInFlc.value < 50000000);

    const buyContract = (list: any) => {
      basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: formData.isStake ? '/stake/store' : '/contracts/store',
          data: formData.isStake
            ? {
              item: {
                config_stake_id: String(contractId(list).value),
                amount: sumInFlc.value,
                app: 'stake-contract',
                id: 'stake-contract',
                packet_id: String(takeHashValue('packet_id')),
              },
            }
            : {
              item: {
                contract_id: String(contractId(list).value),
                amount: formData.amount,
                app: 'contract',
                id: 1,
              },
              node_id: `${takeHashValue('id')}`,
            },
        },
        setState: ({ fetchState }) => {
          formData.state = fetchState;
        },
        callback: (fetchState, data) => {
          if (fetchState === 'FULFILLED') {
            if (typeof data.data.data[0] === 'string' && data.data.data[0].includes('https://')) {
              window.location.assign(data.data.data[0]);
            }
          }
          if (fetchState === 'REJECTED') {
            toast.warning('Something went wrong');
          }
        },
      });
    };

    const contractsVariables = useSWRV('/contracts/index', null)?.data;

    const contractsList = (list: any) => computed(() => (list || []).filter((item: any) => Number(item.period) !== 6 && Number(item.period) !== 12));
    const numberOfNodes = useSWRV('/contracts/getNumberOfNodes', null)?.data;
    const userNodes = useSWRV('/contracts/userNodes', null).data;

    const dividingNumber = (value: number) => dividingIntoDigits(value);

    const closeModal = () => {
      formData.amount = undefined;
      formData.pickedValue = 24;
    };

    const amountKeydown = (e: any) => {
      const keys = ['Backspace', 'ArrowLeft', 'ArrowRight'];
      if ((/\+|-|=|_/.test(e.key) || !/\.|,|[0-9]/g.test(e.key)) && !keys.includes(e.key)) {
        e.preventDefault();
      }
    };
    const clearUnNumberValuePerPaste = (e: any) => {
      e.target.value = String(e.target.value).replaceAll(' ', '');
      if (!Number(String(e.target.value).replace(',', '.'))) {
        formData.amount = undefined;
      }
    };

    return {
      opened,
      clearUnNumberValuePerPaste,
      amountKeydown,
      closeModal,
      buyContract,
      isErrorInput,
      dividingNumber,
      isNodeContractAmountLessInputAmount,
      nodeContractAmount,
      contractsVariables,
      modalName,
      periodsList,
      formData,
      numberOfNodes,
      userNodes,
      isNodeAmountMoreInputAmount,
      isAmountMoreContractsLimit,
      contractsList,
      sumInUsd,
      oneFlcInUsd,
      oneUsdInFlc,
      sumInFlc,
      errorForStake,
    };
  },
});
