import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TechWorksPlaceholder = _resolveComponent("TechWorksPlaceholder")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ModalConfirm = _resolveComponent("ModalConfirm")!
  const _component_ModalListOfCountries = _resolveComponent("ModalListOfCountries")!
  const _component_ModalTwoAuth = _resolveComponent("ModalTwoAuth")!
  const _component_ModalDefrostQualify = _resolveComponent("ModalDefrostQualify")!
  const _component_ModalInfo = _resolveComponent("ModalInfo")!
  const _component_ModalPaymentSuccess = _resolveComponent("ModalPaymentSuccess")!
  const _component_ModalPaymentFail = _resolveComponent("ModalPaymentFail")!
  const _component_ModalWithdrawFlc = _resolveComponent("ModalWithdrawFlc")!
  const _component_ModalWithdrawFlcAccept = _resolveComponent("ModalWithdrawFlcAccept")!
  const _component_ModalNewContract = _resolveComponent("ModalNewContract")!
  const _component_ModalTopUp = _resolveComponent("ModalTopUp")!
  const _component_ModalCreateServer = _resolveComponent("ModalCreateServer")!
  const _component_ModalOpenSupport = _resolveComponent("ModalOpenSupport")!
  const _component_ModalNewEmail = _resolveComponent("ModalNewEmail")!
  const _component_ModalConfirmationEmail = _resolveComponent("ModalConfirmationEmail")!
  const _component_ModalProductDetails = _resolveComponent("ModalProductDetails")!

  return (_ctx.showTechWorks)
    ? (_openBlock(), _createBlock(_component_TechWorksPlaceholder, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_openBlock(), _createBlock(_component_router_view, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalConfirm, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalListOfCountries, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalTwoAuth, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalDefrostQualify, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalInfo, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalPaymentSuccess, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalPaymentFail, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalWithdrawFlc, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalWithdrawFlcAccept, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalNewContract, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalTopUp, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalCreateServer, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalOpenSupport, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalNewEmail, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalConfirmationEmail, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalProductDetails, { key: _ctx.locale }))
      ], 64))
}