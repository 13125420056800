import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c67b427"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modalInfoList" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    name: _ctx.modalName,
    title: "OTON PRESALE"
  }, {
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        _createElementVNode("li", null, _toDisplayString(_ctx.$t("modal-info-1")), 1),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t("modal-info-2")), 1),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t("modal-info-3")), 1)
      ])
    ]),
    _: 1
  }, 8, ["name"]))
}