/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { AxiosResponse } from 'axios';
import { path } from 'rambda';
import { ChartVolumeItemInterface, MappedChartItemInterface } from '@/interfaces/OtonChartTypes';
import dayjs from 'dayjs';
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';
import {
  FetchStateInterface,
  getFetchState,
  getFetchMutations,
  basicAdapterFetch,
} from '@/store/storeUtils';
import { INIT, PENDING } from '@/utils/statuses';

import { mapChartItems } from '@/utils/api/mapChartItems';

export type OtonVolumeResponse = AxiosResponse<{ data: ChartVolumeItemInterface[] }>;

const otonVolume24hStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<OtonVolumeResponse>(),
  } as FetchStateInterface<OtonVolumeResponse>,

  getters: {
    response(...args): ChartVolumeItemInterface[] {
      const { state } = getterCtx(args);

      const result = path('data.data', state.response) as ChartVolumeItemInterface[];

      return result || [];
    },
    data(...args): MappedChartItemInterface[] {
      const { getters } = getterCtx(args);

      return mapChartItems([...getters.response]);
    },
    volumeOneDay(...args): number {
      const { getters } = getterCtx(args);

      const lastItem = getters.response.slice(-1)[0];

      if (!lastItem) {
        return 0;
      }

      return lastItem.total;
    },

    transactionsOneDay(...args): number {
      const { getters } = getterCtx(args);

      const lastItem = getters.response[0];

      if (!lastItem) {
        return 0;
      }

      return lastItem.transactions_count;
    },

  },
  mutations: {
    ...getFetchMutations(),
    SET_INIT(state) {
      state.fetchState = INIT;
    },
  },
  actions: {
    async fetch(ctx) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      const interval = 24;
      const started_at = dayjs().subtract(24, 'h').format('YYYY-MM-DD HH:mm:ss');
      const finished_at = dayjs().format('YYYY-MM-DD HH:mm:ss');
      const query = `interval=${interval}&started_at=${started_at}&finished_at=${finished_at}`;

      await basicAdapterFetch({
        method: () => api.adapter.sendByGet(
          `/lp/oton-usdt/statistics/volume?${query}`,
        ),
        setState: commit.SET_STATE,
      });
    },
  },
});

export default otonVolume24hStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, otonVolume24hStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, otonVolume24hStore);
